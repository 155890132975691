import _ from 'lodash';
import spacetime from 'spacetime';

import {
  ASSET_ADD,
  ASSET_ADD_SUCCESS,
  ASSET_ADD_FAIL,
  ASSET_DELETE,
  ASSET_DELETE_SUCCESS,
  ASSET_DELETE_FAIL,
  ASSET_PAYMENT,
  ASSET_PAYMENT_SUCCESS,
  ASSET_PAYMENT_FAIL,
  ASSET_TRANSFER,
  ASSET_TRANSFER_SUCCESS,
  ASSET_TRANSFER_FAIL,
  ASSET_UPDATE,
  ASSET_UPDATE_SUCCESS,
  ASSET_UPDATE_FAIL,
  ASSETS_LOADING,
  ASSETS_LOADING_SUCCESS,
  ASSETS_LOADING_FAIL,
} from "./types";

const INITIAL_STATE = {
  assets: [],
  assetsMap: {},
  assetsLoaded: false,
  loading: false,
  errorMessage: '',
}

const updateState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ASSET_ADD:
    case ASSET_DELETE:
    case ASSET_PAYMENT:
    case ASSET_TRANSFER:
    case ASSET_UPDATE:
    case ASSETS_LOADING:
      return {
        ...state,
        loading: true,
      }
    case ASSET_ADD_SUCCESS: {
      const assetsMap = state.assetsMap;
      assetsMap[action.payload.asset.assetId.assetId] = action.payload.asset;
      const assets = _.sortBy(_.values(assetsMap), [function(o) { return spacetime(o.createdAt).epoch; }]);
      return {
        ...state,
        loading: false,
        assetsLoaded: true,
        assets,
        assetsMap,
      }
    }
    case ASSET_ADD_FAIL:
    case ASSET_DELETE_FAIL:
    case ASSET_PAYMENT_FAIL:
    case ASSET_TRANSFER_FAIL:
    case ASSETS_LOADING_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      }
    case ASSET_UPDATE_FAIL: {
      let assetsMap = state.assetsMap;
      if (_.has(action.payload,'asset') && (action.payload.asset.ownershipStatus !== 'CANCELLED' || action.payload.side === 'seller')) {
        assetsMap[action.payload.asset.assetId.assetId] = action.payload.asset;
      }
      const assets = _.sortBy(_.values(assetsMap), [function(o) { return spacetime(o.createdAt).epoch; }]);
      return {
        ...state,
        loading: false,
        assets,
        assetsMap,
        errorMessage: action.payload.errorMessage,
      }
    }
    case ASSET_DELETE_SUCCESS: {
      const assetsMap = state.assetsMap;
      delete assetsMap[action.payload.assetId];
      const assets = _.sortBy(_.values(assetsMap), [function(o) { return spacetime(o.createdAt).epoch; }]);
      return {
        ...state,
        loading: false,
        assetsLoaded: true,
        assets,
        assetsMap,
      }
    }
    case ASSETS_LOADING_SUCCESS: {
      const assetsMap = {};
      _.forEach(action.payload.assets, asset => {
        if (!_.has(assetsMap, asset.assetId.assetId)) {
          assetsMap[asset.assetId.assetId] = asset;
        } else if (spacetime(assetsMap[asset.assetId.assetId].createdAt, 'America/New_York').epoch < spacetime(asset.createdAt, 'America/New_York').epoch) {
          assetsMap[asset.assetId.assetId] = asset;
        }
      });
      const assets = _.sortBy(_.values(assetsMap), [function(o) { return spacetime(o.createdAt).epoch; }]);
      return {
        ...state,
        loading: false,
        assetsLoaded: true,
        assets,
        assetsMap,
      }
    }
    case ASSET_PAYMENT_SUCCESS:
    case ASSET_TRANSFER_SUCCESS:
    case ASSET_UPDATE_SUCCESS: {
      let assetsMap = state.assetsMap;
      if (action.payload.asset !== 'CANCELLED') {
        assetsMap[action.payload.asset.assetId.assetId] = action.payload.asset;
      }
      const assets = _.sortBy(_.values(assetsMap), [function(o) { return spacetime(o.createdAt).epoch; }]);
      return {
        ...state,
        loading: false,
        assets,
        assetsMap,
      }
    }
    default:
      return state;
  }
}

export default updateState;
