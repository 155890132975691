import React, {Component} from 'react';
import {
  FormControl,
  Input,
  Button,
  Icon,
  VStack,
  HStack,
  Text,
  InputGroup,
  InputLeftAddon,
  WarningOutlineIcon,
} from 'native-base';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';
import JoditEditor from "jodit-react";
import { v4 as uuid} from 'uuid';
import {withAuth0} from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import spacetime from 'spacetime';
import _ from 'lodash';

import AddImage from './AddImage';
import {addAssetImage} from '../util/api';
import {connect} from 'react-redux';
import {getClient} from '../data/client/actions';
import {addAsset} from '../data/assets/actions';
import {withRouter} from '../util/router';

const INIT_STATE = {
  description: '',
  descriptionDirty: false,
  name: '',
  nameDirty: false,
  purchaseBasis: '',
  purchaseBasisDirty: false,
  creatorName: '',
  creatorNameDirty: false,
  dateAcquired: '',
  dateAcquiredDirty: false,
  files: [],
  filesDirty: false,
  assetId: '',
  processing: false,
  showModal: false,
}

class AddAsset extends Component {
  constructor(props) {
    super(props);

    Modal.setAppElement(document.getElementById('root'));

    this.editor = React.createRef();

    this.state = INIT_STATE;
  }

  reset = () => {
    this.setState(INIT_STATE);
  }

  isInvalid = () => {
    return this.isDescriptionInvalid() || this.isCreatorNameInvalid() || this.isNameInvalid() || this.isPurchaseBasisInvalid() || this.isDateAcquiredInvalid() || this.isFilesInvalid();
  }

  isDescriptionInvalid = () => {
    return _.isNil(this.state.description) || _.isEmpty(_.trim(this.state.description));
  }

  isNameInvalid = () => {
    return _.isNil(this.state.name) || _.isEmpty(this.state.name);
  }

  isPurchaseBasisInvalid = () => {
    return _.isNil(this.state.purchaseBasis) || _.isEmpty(this.state.purchaseBasis) || !_.has(this.state.purchaseBasis, 'floatValue') || _.isNil(this.state.purchaseBasis.floatValue) || this.state.purchaseBasis.floatValue <= 0;
  }

  isCreatorNameInvalid = () => {
    return _.isNil(this.state.creatorName) || _.isEmpty(this.state.creatorName);
  }

  isDateAcquiredInvalid = () => {
    return _.isNil(this.state.dateAcquired) || _.isEmpty(this.state.dateAcquired) || !spacetime(this.state.dateAcquired.formattedValue).isValid() || spacetime(this.state.dateAcquired.formattedValue).isAfter(spacetime.now());
  }

  isFilesInvalid = () => {
    return this.state.files.length !== 1;
  }

  addFiles = (files) => {
    this.setState({
      files,
      filesDirty: true,
    })
  }

  render = () => {
    return (
      <>
        <Button
          variant={"outline"}
          leftIcon={<Icon as={FontAwesomeIcon} icon={faPlus} color={"primary.600"} />}
          onPress={() => {
            this.setState({
              showModal: !this.state.showModal,
            })
          }}
          maxHeight={'48px'}
        >Asset</Button>
        <Modal
          isOpen={this.state.showModal}
          onClose={() => {
            this.setState({
              showModal: false,
            })
          }}
          onAfterOpen={() => {
            this.setState({
              assetId: uuid(),
            });
          }}
          style={
            {
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
              },
              content: {
                margin: 'auto',
                maxWidth: '75%',
                height: 'fit-content',
              },
            }
          }
        >
          <VStack style={{width: '100%'}}>
            <HStack>
              <Text fontSize={"2xl"} fontWeight={"bold"}>
                Add an Asset
              </Text>
            </HStack>
            <HStack>
              <VStack flex={1} pr={3}>
                <FormControl mt="3" isInvalid={this.state.nameDirty && this.isNameInvalid()} isRequired={true}>
                  <FormControl.Label>Name</FormControl.Label>
                  <Input onChangeText={(val) => {
                    this.setState({
                      nameDirty: true,
                      name: val,
                    });
                  }} />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid name.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl mt="3" isInvalid={this.state.filesDirty && this.isFilesInvalid()} isRequired={true}>
                  <FormControl.Label>Image</FormControl.Label>
                  <AddImage files={this.state.files} addFiles={this.addFiles} />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please upload an image for your Asset.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl mt="3" isInvalid={this.state.purchaseBasisDirty && this.isPurchaseBasisInvalid()} isRequired={true}>
                  <FormControl.Label>Purchase Basis</FormControl.Label>
                  <InputGroup w={{
                    base: "70%",
                    md: "285"
                  }}>
                    <InputLeftAddon children={"$"} />
                    <NumberFormat thousandSeparator={true} placeholder="123.45" customInput={Input} onValueChange={(val) => {
                      this.setState({
                        purchaseBasisDirty: true,
                        purchaseBasis: val,
                      });
                    }} />
                  </InputGroup>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Purchase Price.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl mt="3" isInvalid={this.state.dateAcquiredDirty && this.isDateAcquiredInvalid()} isRequired={true}>
                  <FormControl.Label>Date Acquired</FormControl.Label>
                  <NumberFormat customInput={Input} format="##/##/####" placeholder="MM/DD/YYYY" mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']} onValueChange={(val) => {
                    this.setState({
                      dateAcquiredDirty: true,
                      dateAcquired: val,
                    });
                  }} />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    {spacetime(this.state.dateAcquired.formattedValue).isAfter(spacetime.now()) ? 'Please enter a date that is not in the future.' : 'Please enter a valid date.'}
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl mt="3" isInvalid={this.state.creatorNameDirty && this.isCreatorNameInvalid()} isRequired={true}>
                  <FormControl.Label>Creator Name</FormControl.Label>
                  <Input onChangeText={(val) => {
                    this.setState({
                      creatorNameDirty: true,
                      creatorName: val,
                    });
                  }} />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Creator Name.
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
              <VStack flex={1} pl={3} height={"100%"} minH={"100%"}>
                <FormControl mt="3" flex={1} h={"100%"} isInvalid={this.state.descriptionDirty && this.isDescriptionInvalid()} isRequired={true}>
                  <FormControl.Label>Description</FormControl.Label>
                  <JoditEditor
                    ref={this.editor}
                    value={this.state.description}
                    config={{
                      maxHeight: "100%",
                    }}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => {
                      this.setState({
                        descriptionDirty: true,
                        description: newContent,
                      });
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Description.
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
            </HStack>
            <HStack mt={5} space={"md"} justifyContent={"flex-end"}>
              <Button variant={"outline"} onPress={() => {
                this.reset();
                this.setState({
                  showModal: false,
                });
              }}>Cancel</Button>
              <Button
                isLoading={this.state.processing}
                isLoadingText={"Save"}
                isDisabled={this.state.processing || this.isInvalid()}
                onPress={async () => {
                  this.setState({
                    processing: true,
                  })
                  try {
                    const claims = await this.props.auth0.getIdTokenClaims();
                    const assetImage = await addAssetImage(this.state.assetId, this.state.files[0], claims.__raw);
                    await this.props.addAsset(
                      {
                        assetId: this.state.assetId,
                        assetName: this.state.name,
                        assetImages: [assetImage],
                        purchaseBasis: {
                          currency: 'USD',
                          value: this.state.purchaseBasis.floatValue,
                        },
                        dateAcquired: spacetime(this.state.dateAcquired.formattedValue).format('iso-short'),
                        creatorName: this.state.creatorName,
                        assetDescription: this.state.description
                      },
                      claims.__raw
                    );
                    this.reset();
                    this.setState({
                      showModal: false,
                      processing: false,
                    })
                    this.props.onSuccess();
                  } catch (_error) {
                    console.error(_error);
                    toast.error("There was an error processing your request.");
                  } finally {
                    this.setState({
                      processing: false,
                    });
                  }
                }}
              >
                Save
              </Button>
            </HStack>
          </VStack>
          <ToastContainer
            position={"top-center"}
            theme={"colored"}
            pauseOnFocusLoss={false}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client
  };
};

export default connect(
    mapStateToProps,
    {getClient, addAsset}
)(withAuth0(withRouter(AddAsset)));