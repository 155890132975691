import {
  Box,
  HStack,
  StatusBar,
  Image,
  Icon,
  Menu,
  Pressable,
} from 'native-base';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {useAuth0} from '@auth0/auth0-react';

export default function AppBar() {
  const { user, logout } = useAuth0();
  return <>
    <StatusBar bg="white" barStyle="light-content" />
    <Box safeAreaTop bg="white" />
    <HStack bg="white" px="32px" py="24px" justifyContent="space-between" alignItems="center" w="100%">
      <HStack alignItems="center">
        <Image source={{uri: "https://cdn.copyforward.com/logo.png"}} height={45} width={243.46} alt={'CopyForward'}/>
      </HStack>
      <HStack>
        <Menu trigger={triggerProps => {
          return <Pressable accessibilityLabel={"Your information"} {...triggerProps}>
            <Icon as={FontAwesomeIcon} icon={faUser} size={"lg"} />
          </Pressable>
        }}>
          <Menu.Item isDisabled>{`Email: ${user.email}`}</Menu.Item>
          <Menu.Item onPress={() => logout()}>Logout</Menu.Item>
          <Menu.Item isDisabled _text={{fontSize: "2xs"}}>{`Version ${process.env.REACT_APP_VERSION}`}</Menu.Item>
        </Menu>
      </HStack>
    </HStack>
  </>;
}
