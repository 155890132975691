import React, {Component, useState} from 'react';
import {
  Button,
  VStack,
  HStack,
  Text,
  Icon,
  IconButton,
  Box,
  Center,
  Container,
  Heading,
  Divider,
  Radio,
  FormControl,
  Input,
  WarningOutlineIcon,
  Select,
  Spinner,
} from 'native-base';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faCcVisa, faCcMastercard, faCcAmex, faCcDiscover, faCcPaypal} from '@fortawesome/free-brands-svg-icons';
import {faX} from '@fortawesome/free-solid-svg-icons';
import numeral from 'numeral';
import ReactHtmlParser from 'react-html-parser';
import {withAuth0} from '@auth0/auth0-react';
import {toast, ToastContainer} from 'react-toastify';
import {
  PayPalScriptProvider,
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalHostedFields,
  // PayPalMarks,
  PayPalButtons
} from "@paypal/react-paypal-js";
import _ from 'lodash';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
  getTerms,
  getPayPalClientToken,
  createPayPalOrder,
} from '../util/api';
import {withRouter} from '../util/router';
import {STATES_LIST} from '../util';
import {getClient} from '../data/client/actions';
import {completePayment, completeTransfer} from '../data/assets/actions';
import cardMarks from '../assets/card-marks.png';
import {ExhibitA} from './ExhibitA';

const Submit = connect(null, {completePayment})((props) => {
  const hostedFields = usePayPalHostedFields();
  const [processing, setProcessing] = useState(false);

  const submitHandler = async () => {
    if (typeof hostedFields.cardFields.submit !== 'function') return;
    setProcessing(true);
    const claims = await props.auth0.getIdTokenClaims();
    try {
      let orderId;
      if (props.state.paymentMethod === 'card') {
        const response = await hostedFields.cardFields.submit({
          cardholderName: props.state.cardholderName,
          billingAddress: {
            streetAddress: props.state.address,
            region: props.state.state,
            locality: props.state.city,
            postalCode: props.state.zip,
            countryCodeAlpha2: 'US'
          }
        });
        orderId = response.orderId;
      }
      const _response = await props.completePayment(
        props.state.asset.transferredFromId.transferId,
        {
          orderId,
          termsVersion: props.state.termsVersion,
          paymentMethod: 'card',
        },
        claims.__raw,
      );
      props.onSuccess(_response)
      setProcessing(false);
    } catch (error) {
      console.error('submitHandler error', error);
      toast.error('There was an error processing your request. Please try again later.');
      setProcessing(false);
    }
  }

  return <Button isLoading={processing} isLoadingText={"Agree, Sign, and Pay"} isDisabled={processing} onPress={submitHandler}>Agree, Sign, and Pay</Button>
})

class BuyerCompleteTransfer extends Component {
  constructor(props) {
    super(props);

    Modal.setAppElement(document.getElementById('root'));

    this.state = {
      showModal: false,
      processing: false,
      loadingTerms: true,
      terms: '',
      termsVersion: '',
      asset: props.asset,
      loadingPayPalClientToken: false,
      paymentMethod: '',
      paypalClientToken: '',
      paypalInstance: null,
    }
  }

  componentDidMount = async () => {
    if (_.isEmpty(this.props.client)) {
      const claims = await this.props.auth0.getIdTokenClaims();
      await this.props.getClient(claims.__raw);
    }
  }

  completeTransfer = async () => {
    this.setState({
      processing: true,
    });
    try {
      const claims = await this.props.auth0.getIdTokenClaims();
      await this.props.completeTransfer(
        this.state.asset.transferredFromId.transferId,
        {
          termsVersion: this.state.termsVersion,
        },
        claims.__raw,
      );
      this.setState({
        asset: this.props.assetsMap[this.state.asset.assetId.assetId],
      });
    } catch (error) {
      this.setState({
        showModal: false,
      });
      toast.error(this.props.errorMessage || 'There was an error processing your request. Please try again later.');
      this.props.onError(this.props.errorMessage);
    }
    this.setState({
      processing: false,
    });
  }

  onSuccess = async () => {
    this.props.onSuccess();
    this.setState({
      showModal: false,
    });
  }

  getPayPalClientToken = async () => {
    try {
      this.setState({loadingPayPalClientToken: true});
      const claims = await this.props.auth0.getIdTokenClaims();
      const response = await getPayPalClientToken(claims.__raw);
      this.setState({
        loadingPayPalClientToken: false,
        paypalClientToken: response.data.client_token,
      });
    } catch (error) {
      console.error(error);
      toast.error('There was an error processing your request. Please try again later');
    }
  }

  createCardOrder = async () => {
    try {
      const claims = await this.props.auth0.getIdTokenClaims();
      const response = await createPayPalOrder(
        {
          amount: numeral(this.state.asset.transferredFromId.amount).format('0.00'),
          transferId: this.state.asset.transferredFromId.transferId,
        },
        claims.__raw,
      );
      return response.data.id;
    } catch (error) {
      console.error(error);
      toast.error('There was an error processing your request. Please try again later.');
    }
  }

  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: this.state.asset.transferredFromId.amount,
          currency_code: 'USD',
        }
      }],
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      }
    });
  }

  onApprove = async (data) => {
    try {
      const claims = await this.props.auth0.getIdTokenClaims();
      await this.props.completePayment(
        this.state.asset.transferredFromId.transferId,
        {
          orderId: data.orderID,
          termsVersion: this.state.termsVersion,
          paymentMethod: 'paypal',
        },
        claims.__raw,
      );
      this.setState({
        showModal: false,
      });
      this.props.onSuccess();
    } catch (error) {
      this.setState({
        showModal: false,
      });
      toast.error(this.props.errorMessage || 'There was an error processing your request. Please try again later.');
      this.props.onError();
    }
  }

  render() {
    const preExpanded = ['exhibitA'];
    if (!_.has(this.props.client, 'lastBuyerTermsViewed') || this.props.client.lastBuyerTermsViewed !== this.state.termsVersion) {
      preExpanded.push('terms');
    }
    return (
      <Container>
        <Button
          onPress={() => {
            this.setState({
              showModal: !this.state.showModal,
            });
          }}
        >Transfer</Button>
        <Modal
          isOpen={this.state.showModal}
          onAfterOpen={async () => {
            try {
              this.setState({ loadingTerms: true });
              const claims = await this.props.auth0.getIdTokenClaims();
              const terms = await getTerms(claims.__raw);
              await this.getPayPalClientToken();
              this.setState({
                loadingTerms: false,
                terms: terms.data.terms,
                termsVersion: terms.data.version,
              });
            } catch (error) {
              console.error(error);
            }
          }}
          onAfterClose={this.props.onUpdate}
          style={
            {
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
              },
              content: {
                margin: 'auto',
                maxWidth: '75%',
                maxHeight: '90%',
                minHeight: '90%',
                borderRadius: 6,
              }
            }
          }
        >
          {this.state.paypalClientToken === '' && this.state.terms === '' && (
            <Center height={'100%'} width={'100%'}>
              <HStack space={3}>
                <Spinner accessibilityLabel="Loading" color={"primary.600"} />
                <Heading color="primary.600" fontSize="md">
                  Loading
                </Heading>
              </HStack>
            </Center>
          )}
          {this.state.paypalClientToken !== '' && this.state.terms !== '' && (
            <PayPalScriptProvider options={{
              'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
              components: 'buttons,hosted-fields,marks',
              'data-client-token': this.state.paypalClientToken,
              vault: false,
            }}>
              <PayPalHostedFieldsProvider
                createOrder={this.createCardOrder}>
                <VStack h={"100%"} maxH={"100%"}>
                  <HStack justifyContent={'space-between'} mb={3}>
                    <Heading size={"lg"}>
                      {this.state.asset.ownershipStatus === 'PENDING_PAYMENT' ? 'Complete your payment' : 'Complete Transfer'}
                    </Heading>
                    <IconButton icon={<Icon as={FontAwesomeIcon} icon={faX} size={"md"} />} onPress={() => {
                      this.setState({
                        showModal: !this.state.showModal,
                      });
                    }} />
                  </HStack>
                  <HStack flex={1}>
                    <HStack flex={1}>
                      <VStack alignItems={'flex-start'} flex={1}>
                        <Box width={'100%'} flex={1} p={2}>
                          <Text fontSize={'md'} fontWeight={'medium'}>{this.props.asset.assetId.assetName}</Text>
                          <Text fontSize={'xs'} isTruncated lineHeight={'md'}>Asset ID: {this.props.asset.assetId.assetId}</Text>
                          <Center mt={4}>
                            <img
                                src={!_.isNil(this.props.asset.assetId.assetImages) && _.isArray(this.props.asset.assetId.assetImages) ? this.props.asset.assetId.assetImages[0].signedUrl : ''}
                                style={styles.image}
                                alt={this.props.asset.assetId.assetName || 'Unknown'}
                            />
                          </Center>
                        </Box>
                      </VStack>
                    </HStack>
                    <HStack flex={2}>
                      <VStack flex={1} overflow={'hidden'}>
                        <Accordion allowMultipleExpanded={true} preExpanded={preExpanded}>
                          <AccordionItem uuid={'terms'}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                  <Text>Terms and Conditions</Text>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                           <AccordionItemPanel>
                              {ReactHtmlParser(this.state.terms)}
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem uuid={'exhibitA'}>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <Text>Exhibit A</Text>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <VStack>
                                <ExhibitA
                                  seller={this.state.asset.transferredFrom}
                                  buyer={this.props.client}
                                  asset={this.state.asset.assetId}
                                  purchasePrice={this.state.asset.transferredFromId.purchasePrice.value}
                                  serviceFee={{percent: this.state.asset.transferredFromId.serviceFee, amount: this.state.asset.transferredFromId.serviceFeeAmount.value}}
                                  royalties={{
                                    firstSeller: {
                                      percent: this.state.asset.transferredFromId.royalties.firstSeller,
                                      amount: this.state.asset.transferredFromId.royaltiesAmount.firstSeller.value,
                                    },
                                    laterOwners: {
                                      percent: this.state.asset.transferredFromId.royalties.laterOwners,
                                      amount: this.state.asset.transferredFromId.royaltiesAmount.laterOwners.value,
                                    },
                                    thirdParties: {
                                      percent: this.state.asset.transferredFromId.royalties.thirdParties,
                                      amount: this.state.asset.transferredFromId.royaltiesAmount.thirdParties.value,
                                    },
                                  }}
                                  amount={this.state.asset.transferredFromId.amount}
                                />
                                <Divider width={'75%'} alignSelf={'center'} mt={3} />
                                <VStack mt={3}>
                                  <Text textAlign={'center'} fontWeight={'bold'} mb={2}>Select payment method below</Text>
                                  <HStack>
                                    <Box flex={1}>
                                      <Radio.Group
                                        space={3}
                                        name={'paymentMethod'}
                                        value={this.state.paymentMethod}
                                        onChange={async method => {
                                          this.setState({paymentMethod: method});
                                        }}
                                      >
                                        <Radio value={'paypal'}>
                                          <img src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" border="0" alt="PayPal" height={26} />
                                          {/*<FontAwesomeIcon icon={faCcPaypal} size={"2x"} />*/}
                                        </Radio>
                                        <Radio value={'card'}>
                                          {/*<HStack space={3}>*/}
                                          {/*  <FontAwesomeIcon icon={faCcVisa} size={"2x"} />*/}
                                          {/*  <FontAwesomeIcon icon={faCcMastercard} size={"2x"} />*/}
                                          {/*  <FontAwesomeIcon icon={faCcAmex} size={"2x"} />*/}
                                          {/*  <FontAwesomeIcon icon={faCcDiscover} size={"2x"} />*/}
                                          {/*</HStack>*/}
                                          <img height={26} src={cardMarks} alt={'Visa | MasterCard | American Express | Discover'} border={0} />
                                        </Radio>
                                      </Radio.Group>
                                    </Box>
                                    {this.state.paymentMethod === 'paypal' && this.state.asset.ownershipStatus === 'PENDING_TRANSFER' && (
                                        <Box flex={2}>
                                          <Text>Once the transfer is complete, you'll be directed to use PayPal to complete your payment.</Text>
                                        </Box>
                                    )}
                                    {this.state.paymentMethod === 'paypal' && this.state.asset.ownershipStatus === 'PENDING_PAYMENT' && (
                                        <Box flex={2}>
                                          <Text>Your transfer is nearly complete! Complete your payment with PayPal.</Text>
                                        </Box>
                                    )}
                                    <Box flex={2} style={{display: this.state.paymentMethod === 'card' && !this.state.loadingPayPalClientToken ? 'block' : 'none'}}>
                                      <Box flex={4}>
                                        <FormControl mt="3" isRequired={true}>
                                          <FormControl.Label>Credit Card Number</FormControl.Label>
                                          <Box style={styles.inputContainer}>
                                            <PayPalHostedField
                                                id="card-number"
                                                style={styles.input}
                                                hostedFieldType="number"
                                                options={{
                                                  selector: "#card-number",
                                                  placeholder: "4111 1111 1111 1111",
                                                }}
                                            />
                                          </Box>
                                          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                            Please enter a valid Credit Card Number.
                                          </FormControl.ErrorMessage>
                                        </FormControl>
                                        <HStack flex={1} space={3}>
                                          <Box flex={1}>
                                            <FormControl mt="3" isRequired={true}>
                                              <FormControl.Label>CVV</FormControl.Label>
                                              <Box style={styles.inputContainer}>
                                                <PayPalHostedField
                                                    id="cvv"
                                                    style={styles.input}
                                                    hostedFieldType="cvv"
                                                    options={{
                                                      selector: "#cvv",
                                                      placeholder: "123",
                                                      maskInput: true,
                                                    }}
                                                />
                                              </Box>
                                              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                                Please enter a valid CVV.
                                              </FormControl.ErrorMessage>
                                            </FormControl>
                                          </Box>
                                          <Box flex={1}>
                                            <FormControl mt="3" isRequired={true}>
                                              <FormControl.Label>Expiration Date</FormControl.Label>
                                              <Box style={styles.inputContainer}>
                                                <PayPalHostedField
                                                    id="expiration-date"
                                                    style={styles.input}
                                                    hostedFieldType="expirationDate"
                                                    options={{
                                                      selector: "#expiration-date",
                                                      placeholder: "MM/YYYY",
                                                    }}
                                                />
                                              </Box>
                                              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                                Please enter a valid Expiration Date.
                                              </FormControl.ErrorMessage>
                                            </FormControl>
                                          </Box>
                                        </HStack>
                                        <FormControl mt="3" isRequired={true}>
                                          <FormControl.Label>Cardholder Name</FormControl.Label>
                                          <Input onChangeText={(val) => {
                                            this.setState({
                                              cardholderNameDirty: true,
                                              cardholderName: val
                                            });
                                          }} />
                                          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                            Please enter a valid Cardholder Name.
                                          </FormControl.ErrorMessage>
                                        </FormControl>
                                        <FormControl mt="3" isRequired={true}>
                                          <FormControl.Label>Address</FormControl.Label>
                                          <Input onChangeText={(val) => {
                                            this.setState({
                                              addressDirty: true,
                                              address: val
                                            });
                                          }} />
                                          <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                            Please enter a valid Street Address.
                                          </FormControl.ErrorMessage>
                                        </FormControl>
                                        <HStack flex={1} space={3}>
                                          <Box flex={3}>
                                            <FormControl mt="3" isRequired={true}>
                                              <FormControl.Label>City</FormControl.Label>
                                              <Input onChangeText={(val) => {
                                                this.setState({
                                                  cityDirty: true,
                                                  city: val
                                                });
                                              }} />
                                              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                                Please enter a valid City.
                                              </FormControl.ErrorMessage>
                                            </FormControl>
                                          </Box>
                                          <Box flex={1}>
                                            <FormControl mt="3" isRequired={true}>
                                              <FormControl.Label>State</FormControl.Label>
                                              <Select selectedValue={this.state.state} placeholder={'--'} onValueChange={item => this.setState({state: item})}>
                                                {_.map(STATES_LIST, _state => <Select.Item label={_state.abbreviation} value={_state.abbreviation} />)}
                                              </Select>
                                              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                                Please select a valid State.
                                              </FormControl.ErrorMessage>
                                            </FormControl>
                                          </Box>
                                          <Box flex={2}>
                                            <FormControl mt="3" isRequired={true}>
                                              <FormControl.Label>Zip Code</FormControl.Label>
                                              <Input onChangeText={(val) => {
                                                this.setState({
                                                  zipDirty: true,
                                                  zip: val
                                                });
                                              }} />
                                              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                                Please enter a valid Zip Code.
                                              </FormControl.ErrorMessage>
                                            </FormControl>
                                          </Box>
                                        </HStack>
                                      </Box>
                                    </Box>
                                  </HStack>
                                </VStack>
                              </VStack>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                        <Center paddingBottom={5} paddingTop={5}>
                          {this.state.paymentMethod === 'card' && (
                            <Submit state={this.state} auth0={this.props.auth0} onSuccess={this.onSuccess} />
                          )}
                          {this.state.paymentMethod === 'paypal' && this.state.asset.ownershipStatus === 'PENDING_TRANSFER' && (
                            <Button isLoading={this.state.processing} isLoadingText={"Agree and Sign"} isDisabled={this.state.processing} onPress={() => this.completeTransfer()}>Agree and Sign</Button>
                          )}
                          {this.state.paymentMethod === 'paypal' && this.state.asset.ownershipStatus === 'PENDING_PAYMENT' && (
                            <PayPalButtons
                              fundingSource={this.state.paymentMethod}
                              style={{color: 'white', label: 'pay', height: 43}}
                              createOrder={this.createOrder}
                              onApprove={this.onApprove}
                              onError={(error) => console.error(error)}
                            />
                          )}
                          {this.state.paymentMethod === '' && (
                            <Button isDisabled={true}>Agree, Sign, and Pay</Button>
                          )}
                        </Center>
                      </VStack>
                    </HStack>
                  </HStack>
                </VStack>
              </PayPalHostedFieldsProvider>
            </PayPalScriptProvider>
          )}
          <ToastContainer
            position={"top-center"}
            theme={"colored"}
            pauseOnFocusLoss={false}
          />
        </Modal>
      </Container>
    );
  }
}

const styles = {
  image: {
    display: 'block',
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '200px',
  },
  inputContainer: {
    flex: 1,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'rgb(212, 212, 212)',
    height: 31.5,
    '-webkit-box-align': 'center'
  },
  input: {
    fontSize: 12,
    cursor: 'auto',
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: '400',
    color: 'rgb(23, 23, 23)',
    lineHeight: '1.5em',
    paddingRight: 12,
    paddingLeft: 12,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    height: 15.5,
    fontStyle: 'normal',
    fontFamily: 'Merriweather Sans',
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client,
    assetsMap: data.assets.assetsMap,
    errorMessage: data.assets.errorMessage,
  };
};

export default connect(
    mapStateToProps,
    {getClient, completePayment, completeTransfer}
)(withAuth0(withRouter(BuyerCompleteTransfer)));
