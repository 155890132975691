import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {
  persistStore,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE
} from "redux-persist";
import {
  createStateSyncMiddleware,
  initMessageListener
} from "redux-state-sync";

import rootReducer from "./reducers";

const configureStore = () => {
  const syncConfig = {
    blacklist: [PERSIST, PURGE, REGISTER, REHYDRATE]
  };
  const store = createStore(
      rootReducer,
      {},
      applyMiddleware(thunk, createStateSyncMiddleware(syncConfig))
  );
  initMessageListener(store);
  const persistor = persistStore(store);

  return { persistor, store };
};

export default configureStore;
