import React, {Component} from 'react';
import {
  FormControl,
  Input,
  Button,
  Icon,
  VStack,
  HStack,
  Text,
  WarningOutlineIcon,
  IconButton, Box,
} from 'native-base';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {withAuth0} from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';

import {connect} from 'react-redux';
import {deleteAsset} from '../data/assets/actions';
import {withRouter} from '../util/router';

const INIT_STATE = {
  showModal: false,
  deleteConfirm: '',
  deleteConfirmDirty: false,
  processing: false,
}

const DELETE_CONFIRM = 'delete';

class DeleteAsset extends Component {
  constructor(props) {
    super(props);

    Modal.setAppElement(document.getElementById('root'));

    this.state = INIT_STATE;
  }

  reset = () => {
    this.setState(INIT_STATE);
  }

  isInvalid = () => {
    return this.state.deleteConfirm !== DELETE_CONFIRM;
  }

  isDeleteConfirmInvalid = () => {
    return _.isNil(this.state.deleteConfirm) || _.isEmpty(_.trim(this.state.deleteConfirm));
  }

  render() {
    if (!_.has(this.props.asset.assetId, 'previousOwners') || this.props.asset.assetId.previousOwners.length === 0) {
      return (
        <Box flex={1} mx={2}>
          <IconButton
            variant={"outline"}
            icon={<Icon as={FontAwesomeIcon} icon={faTrash} color={"primary.600"} />}
            size={"lg"}
            onPress={() => {
              this.setState({showModal: !this.state.showModal});
            }}
          />
          <Modal
            isOpen={this.state.showModal}
            style={
              {
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                },
                content: {
                  margin: 'auto',
                  maxWidth: '25%',
                  height: 'fit-content',
                }
              }
            }
          >
            <VStack>
              <HStack>
                <Text fontSize={"2xl"} fontWeight={"bold"}>
                  Delete "{this.props.asset.assetId.assetName}"
                </Text>
              </HStack>
              <VStack flex={1} pr={3} mt={3}>
                <Text>Please enter <Text bold>delete</Text> to delete the asset from your inventory.</Text>
                <FormControl mt="3" isInvalid={this.state.deleteConfirmDirty && this.isDeleteConfirmInvalid()} isRequired={true}>
                  <FormControl.Label>Confirm</FormControl.Label>
                  <Input
                    placeholder={'delete'}
                    onChangeText={(val) => {
                      this.setState({
                        deleteConfirmDirty: true,
                        deleteConfirm: val,
                      });
                    }}
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please confirm your intent to delete this asset.
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
              <HStack mt={5} space={"md"} justifyContent={"flex-end"}>
                <Button variant={"outline"} onPress={() => {
                  this.reset();
                  this.setState({showModal: false});
                }}>Cancel</Button>
                <Button
                  isLoading={this.state.processing}
                  isLoadingText={"Delete"}
                  isDisabled={this.state.processing || this.isInvalid()}
                  onPress={async () => {
                    this.setState({processing: true});
                    try {
                      const claims = await this.props.auth0.getIdTokenClaims();
                      await this.props.deleteAsset(this.props.asset.id, claims.__raw);
                      this.reset();
                      this.setState({showModal: false});
                      this.props.onSuccess();
                    } catch (_error) {
                      console.error(_error);
                      toast.error("There was an error processing your request.");
                    } finally {
                      this.setState({processing: false});
                    }
                  }}
                >
                  Delete
                </Button>
              </HStack>
            </VStack>
            <ToastContainer
              position={"top-center"}
              theme={"colored"}
              pauseOnFocusLoss={false}
            />
          </Modal>
        </Box>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client
  };
};

export default connect(
    mapStateToProps,
    {deleteAsset}
)(withAuth0(withRouter(DeleteAsset)));
