import React, {Component} from 'react';
import {
  Box,
  Button,
  Center,
  HStack, Text,
  VStack,
  Icon, Heading, Image,
} from 'native-base';
import {connect} from 'react-redux';
import {withAuth0} from '@auth0/auth0-react';
import _ from 'lodash';
import {toast, ToastContainer} from 'react-toastify';
import ReactPlaceholder from 'react-placeholder';
import numeral from 'numeral';
import ReactHtmlParser from 'react-html-parser';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {parsePhoneNumber} from 'awesome-phonenumber';

import AppBar from './AppBar';
import {getTerms} from '../util/api';
import AssetImage from './AssetImage';
import EditAsset from './EditAsset';
import {withRouter} from '../util/router';
import DeleteAsset from './DeleteAsset';
import {getDate, getOwnershipStatus} from '../util';
import PayPalButton from './PayPalButtons';
import BuyerCompleteTransfer from './BuyerCompleteTransfer';
import SellerCreateTransfer from './SellerCreateTransfer';
import {getClient} from '../data/client/actions';
import TermsButton from './TermsButton';
import CancelTransferButton from './CancelTransferButton';
import {completePayment} from '../data/assets/actions';

class ViewAsset extends Component {
  constructor(props) {
    super(props);

    const {state} = props.router.location;

    this.state = {
      asset: state.asset,
      ready: true,
      showBuyerButtons: _.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], state.asset.ownershipStatus) && !_.isNil(state.asset.transferredFrom) && _.isNil(state.asset.transferredTo),
      showSellerButtons: state.asset.ownershipStatus === 'OWN',
      showAssetHistory: state.asset.assetId.previousOwners.length > 0,
    }
  }

  componentDidMount = () => {}

  updateAsset = (asset) => {
    this.setState({
      asset
    });
  }

  onSellerSuccess = () => {
    toast.success("Your asset transfer request is complete!");
    const asset = this.props.assetsMap[this.state.asset.assetId.assetId];
    this.setState({
      asset,
      showBuyerButtons: _.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], asset.ownershipStatus) && !_.isNil(asset.transferredFrom) && _.isNil(asset.transferredTo),
      showSellerButtons: asset.ownershipStatus === 'OWN',
      showAssetHistory: asset.assetId.previousOwners.length > 0,
    });
   }

  onBuyerSuccess = () => {
    toast.success("Your asset transfer is complete!");
    const asset = this.props.assetsMap[this.state.asset.assetId.assetId];
    this.setState({
      asset,
      showBuyerButtons: _.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], asset.ownershipStatus) && !_.isNil(asset.transferredFrom) && _.isNil(asset.transferredTo),
      showSellerButtons: asset.ownershipStatus === 'OWN',
      showAssetHistory: asset.assetId.previousOwners.length > 0,
    });
  };

  onUpdate = () => {
    const asset = this.props.assetsMap[this.state.asset.assetId.assetId];
    this.setState({
      asset,
    });
  };

  completePayment = async (details) => {
    try {
      const claims = await this.props.auth0.getIdTokenClaims();
      const terms = await getTerms(claims.__raw);

      await this.props.completePayment(
        this.state.asset.transferredFromId.transferId,
        {
          orderId: details.orderID,
          termsVersion: terms.data.version,
          paymentMethod: 'paypal',
        },
        claims.__raw,
      );
      toast.success("Your asset transfer is complete!");
      const asset = this.props.assetsMap[this.state.asset.assetId.assetId];
      this.setState({
        asset,
        showBuyerButtons: _.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], asset.ownershipStatus) && !_.isNil(asset.transferredFrom) && _.isNil(asset.transferredTo),
        showSellerButtons: asset.ownershipStatus === 'OWN',
        showAssetHistory: asset.assetId.previousOwners.length > 0,
      });
    } catch (error) {
      console.error(error);
      toast.error('There was an error processing your request. Please try again later');
    }
  }

  onError = () => {
    toast.error('There was an error processing your request. Please try again later');
  }

  renderSellerButtons = () => {
    if (this.state.showSellerButtons) {
      return (
        <HStack>
          <Box flex={1} mx={2}>
            <EditAsset asset={this.state.asset} onSuccess={() => {
              this.setState({
                asset: this.props.assetsMap[this.state.asset.assetId.assetId],
              });
              toast.success('Your asset has been updated');
            }}/>
          </Box>
          <DeleteAsset
            asset={this.state.asset}
            onSuccess={() => {
              this.props.router.navigate('/');
            }}
          />
          <Box flex={1} mx={2}>
            <SellerCreateTransfer asset={this.state.asset} onSuccess={this.onSellerSuccess} />
          </Box>
        </HStack>
      )
    }
  }

  renderBuyerButtons = () => {
    if (this.state.showBuyerButtons && this.state.asset.ownershipStatus === 'PENDING_TRANSFER') {
      return (
        <Box>
          <BuyerCompleteTransfer
            asset={this.state.asset}
            onSuccess={this.onBuyerSuccess}
            onUpdate={this.onUpdate}
            onError={() => {
              this.props.router.navigate('/', {state: {hasError: true}});
            }}
          />
        </Box>
      )
    }
  }

  renderPayPalButtons = () => {
    if (this.state.showBuyerButtons && this.state.asset.ownershipStatus === 'PENDING_PAYMENT') {
      return (
        <HStack space={4} mt={10} alignSelf={'center'}>
          <Box flex={1}>
            <Box mb={3}>
              <Heading size={"sm"} pb={3} textAlign={"center"}>Your transfer is nearly complete!</Heading>
              <Text textAlign={'center'}>Please complete your payment.</Text>
            </Box>
            <PayPalButton asset={this.state.asset} onSuccess={this.completePayment} onError={this.onError} />
          </Box>
        </HStack>
      )
    }
  }

  renderCancelButton = () => {
    if (_.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], this.state.asset.ownershipStatus) && !_.isNil(this.state.asset.transferredTo)) {
      return (
        <Box>
          <CancelTransferButton
            asset={this.state.asset}
            transferId={this.state.asset.transferredToId.transferId} side={'seller'}
            onSuccess={() => {
              const asset = this.props.assetsMap[this.state.asset.assetId.assetId]
              this.setState({
                asset,
                showSellerButtons: asset.ownershipStatus === 'OWN',
              });
              toast.success('Your transfer has been cancelled');
            }}
            onError={() => {
              const asset = this.props.assetsMap[this.state.asset.assetId.assetId];
              this.setState({
                asset,
                showSellerButtons: asset.ownershipStatus === 'OWN',
              });
            }}
          />
        </Box>
      )
    }
    if (_.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], this.state.asset.ownershipStatus) && _.isNil(this.state.asset.transferredTo) && !_.isNil(this.state.asset.transferredFrom)) {
      return (
        <Box>
          <CancelTransferButton
            asset={this.state.asset}
            transferId={this.state.asset.transferredFromId.transferId} side={'buyer'}
            onSuccess={() => {
              const asset = this.props.assetsMap[this.state.asset.assetId.assetId]
              this.setState({
                asset,
                showBuyerButtons: _.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], asset.ownershipStatus) && !_.isNil(asset.transferredFrom) && _.isNil(asset.transferredTo),
              });
              this.props.router.navigate('/')
              toast.success('Your transfer has been cancelled');
            }}
            onError={() => {
              const asset = this.props.assetsMap[this.state.asset.assetId.assetId]
              this.setState({
                asset,
                showSellerButtons: asset.ownershipStatus === 'OWN',
              });
            }}
          />
        </Box>
      )
    }
  }

  renderTermsButton = () => {
    if (this.state.showAssetHistory) {
      return (
        <Box>
          <TermsButton asset={this.state.asset} />
        </Box>
      )
    }
  }

  render() {
    return (
      <>
        <AppBar />
        <VStack pt={"18px"} pb={"32px"} px={"32px"} w="100%">
          <Center>
            <VStack w={"100%"} maxWidth={"1370px"}>
              <HStack mb={5}>
                <Button
                  variant={"outline"}
                  leftIcon={<Icon as={FontAwesomeIcon} icon={faArrowLeft} color={"primary.600"} />}
                  onPress={() => this.props.router.navigate('/')}
                >Back</Button>
              </HStack>
              <HStack space={6}>
                <Box flex={1}>
                  <VStack>
                    <HStack py={1} alignItems={'center'}>
                      <ReactPlaceholder type='text' rows={1} ready={this.state.ready} showLoadingAnimation={!this.state.ready}>
                        <Text fontSize={'lg'} fontWeight={'400'}>{getOwnershipStatus(this.state.asset.ownershipStatus)}</Text>
                      </ReactPlaceholder>
                      {_.has(this.state.asset.assetId, 'previousOwners') && this.state.asset.assetId.previousOwners.length > 0 && <Image size={'1.5em'} ml={1} source={{uri: 'https://cdn.copyforward.com/icon.png'}} />}
                    </HStack>
                    <HStack space={3} py={1}>
                      <Text bold>Name</Text>
                      <ReactPlaceholder rows={1} ready={this.state.ready} type={"text"} showLoadingAnimation={!this.state.ready}>
                        <Text>{this.state.asset.assetId.assetName}</Text>
                      </ReactPlaceholder>
                    </HStack>
                    <HStack py={1}>
                      <AssetImage image={!_.isNil(this.state.asset.assetId.assetImages) && _.isArray(this.state.asset.assetId.assetImages) ? this.state.asset.assetId.assetImages[0].signedUrl : ''} alt={this.state.asset.assetId.assetName} ready={this.state.ready} />
                    </HStack>
                    <HStack space={3} py={1}>
                      <Text bold>Creator Name</Text>
                      <ReactPlaceholder rows={1} ready={this.state.ready} type={"text"} showLoadingAnimation={!this.state.ready}>
                        <Text>{this.state.asset.assetId.creatorName}</Text>
                      </ReactPlaceholder>
                    </HStack>
                    <HStack flex={1}>
                      <HStack space={3} py={1} pr={3} flex={1} justifyContent={'space-between'}>
                        <Text bold>Purchase Price</Text>
                        <ReactPlaceholder rows={1} ready={this.state.ready} type={"text"} showLoadingAnimation={!this.state.ready}>
                          <Text>{numeral(this.state.asset.purchaseBasis.value).format('$0,0.00')}</Text>
                        </ReactPlaceholder>
                      </HStack>
                      <HStack space={3} py={1} pl={3} flex={1} justifyContent={'space-between'}>
                        <Text bold>Date Acquired</Text>
                        <ReactPlaceholder rows={1} ready={this.state.ready} type={"text"} showLoadingAnimation={!this.state.ready}>
                          <Text>{getDate(this.state.asset.dateAcquired)}</Text>
                        </ReactPlaceholder>
                      </HStack>
                    </HStack>
                    {this.state.asset.ownershipStatus === 'SOLD' && (
                      <HStack flex={1}>
                        <HStack space={3} py={1} pr={3} flex={1} justifyContent={'space-between'}>
                          <Text bold>Sale Price</Text>
                          <ReactPlaceholder rows={1} ready={this.state.ready} type={"text"} showLoadingAnimation={!this.state.ready}>
                            <Text>{numeral(this.state.asset.purchasePrice.value).format('$0,0.00')}</Text>
                          </ReactPlaceholder>
                        </HStack>
                        <HStack space={3} py={1} pl={3} flex={1} justifyContent={'space-between'}>
                          <Text bold>Date Sold</Text>
                          <ReactPlaceholder rows={1} ready={this.state.ready} type={"text"} showLoadingAnimation={!this.state.ready}>
                            <Text>{getDate(this.state.asset.dateSold)}</Text>
                          </ReactPlaceholder>
                        </HStack>
                      </HStack>
                    )}
                  </VStack>
                </Box>
                <Box flex={1}>
                  <VStack>
                    <HStack py={1}>
                      <Text bold>Description</Text>
                    </HStack>
                    <HStack py={1} mr={4}>
                      <Box flex={1}>
                        <Text>
                          {ReactHtmlParser(this.state.asset.assetId.assetDescription)}
                        </Text>
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
                <Box flex={1}>
                  <VStack>
                    <HStack space={3} py={1}>
                      <Text bold>Asset ID</Text>
                      <ReactPlaceholder rows={1} ready={this.state.ready} type={"text"} showLoadingAnimation={!this.state.ready}>
                        <Text>{this.state.asset.assetId.assetId}</Text>
                      </ReactPlaceholder>
                    </HStack>
                    {_.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], this.state.asset.ownershipStatus) && _.isNil(this.state.asset.transferredTo) && !_.isNil(this.state.asset.transferredFrom) && (
                      <HStack space={3} py={1}>
                        <Text bold>Transferring from</Text>
                        <VStack>
                          <Text>{this.state.asset.transferredFrom.firstName} {this.state.asset.transferredFrom.lastName}</Text>
                          <Text>Email: {this.state.asset.transferredFrom.email}</Text>
                          <Text>Phone: {parsePhoneNumber(this.state.asset.transferredFrom.phone || '', 'US').getNumber('national')}</Text>
                        </VStack>
                      </HStack>
                    )}
                    {_.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], this.state.asset.ownershipStatus) && !_.isNil(this.state.asset.transferredTo) && (
                      <HStack space={3} py={1}>
                        <Text bold>Transferring to</Text>
                        <VStack>
                          <Text>{this.state.asset.transferredTo.firstName} {this.state.asset.transferredTo.lastName}</Text>
                          <Text>Email: {this.state.asset.transferredTo.email}</Text>
                          <Text>Phone: {parsePhoneNumber(this.state.asset.transferredTo.phone || '', 'US').getNumber('national')}</Text>
                        </VStack>
                      </HStack>
                    )}
                    <HStack space={4} mt={10}>
                      <Box flex={1} />
                      <HStack space={3}>
                        {this.renderTermsButton()}
                        {this.renderCancelButton()}
                        {this.renderSellerButtons()}
                        {this.renderBuyerButtons()}
                      </HStack>
                      <Box flex={1} />
                    </HStack>
                    <HStack justifyContent={'center'} flex={1}>
                      {this.renderPayPalButtons()}
                    </HStack>
                  </VStack>
                </Box>
              </HStack>
            </VStack>
          </Center>
        </VStack>
        <ToastContainer
          position={"top-center"}
          theme={"colored"}
          pauseOnFocusLoss={false}
        />
      </>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client,
    assetsMap: data.assets.assetsMap,
  };
};

export default connect(
  mapStateToProps,
  {getClient, completePayment}
)(withAuth0(withRouter(ViewAsset)));
