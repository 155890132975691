import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

// import ComponentsReducer from "../../components/reducer";
import DataReducer from "../../data/reducer";

const dataPersistConfig = {
  key: "data",
  storage,
  blacklist: ["client"]
};

export default combineReducers({
  // components: ComponentsReducer,
  data: persistReducer(dataPersistConfig, DataReducer)
});
