import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {Box, HStack, Icon, IconButton} from 'native-base';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';

import AssetImage from './AssetImage';

export default function AddImage({files, addFiles, isDisabled = false}) {
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop: acceptedFiles => {
      addFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    },
    disabled: isDisabled
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
      <>
        {files.length === 0 && (
          <section style={{
            borderWidth: "2px",
            borderRadius: "2px",
            borderStyle: "dashed",
            height: "200px",
            flex: 1,
            flexDirection: "row",
            backgroundColor: "#fafafa",
            color: "#bdbdbd",
            outline: "none",
            transition: "border .24s ease-in-out",
            padding: "10px"
          }}>
            <div style={{
              flexDirection: "row",
              display: "flex",
              height: "200px",
              flex: 1,
              justifyContent: "center",
              alignItems: "center"
            }}
                 {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </section>
        )}
        {files.length > 0 && (
          <HStack mt={"10px"} alignSelf={"center"}>
            <Box display={"inline-block"}>
              <AssetImage image={files[0].preview} alt={files[0].name} ready={true} onLoad={() => { URL.revokeObjectURL(files[0].preview) }} />
            </Box>
            <Box pl={3}>
              <IconButton icon={<Icon as={FontAwesomeIcon} icon={faX} size={"md"} />} onPress={() => {
                addFiles([]);
              }} />
            </Box>
          </HStack>
        )}
      </>
  );
}