import React from "react";
import {Box, Center, Heading, HStack} from 'native-base';

export const NotFound = () => {
  return (
      <Center height={"100vh"} bg={"coolGray.300"}>
        <Box
            borderRadius={10}
            pt={5}
            pb={6}
            bg="white"
            width={558}
            height={545}
            shadow={"card"}
            alignItems={"center"}
            justifyContent={"center"}
        >
          <HStack space={2} justifyContent="center">
            <Heading color="primary.600" fontSize="md">
              Oops! That page was not found.
            </Heading>
          </HStack>
        </Box>
      </Center>
  );
}
