import {CLIENT_SET} from "./types";

const INITIAL_STATE = {};

const updateState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLIENT_SET:
      return {
        ...state,
        ...action.payload.client
      };
    default:
      return state;
  }
};

export default updateState;
