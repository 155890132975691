import React from 'react';
import {extendTheme, NativeBaseProvider} from 'native-base';
import Home from './components/Home';
import {useAuth0} from '@auth0/auth0-react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {Loading} from './components/Loading';
import {NotFound} from './components/NotFound';
import {ProtectedRoute} from './components/ProtectedRoute';
import ViewAsset from './components/ViewAsset';

const theme = extendTheme({
  fontConfig: {
    MerriweatherSans: {
      100: {
        normal: "Merriweather Sans",
        italic: "Merriweather Sans",
      },
      200: {
        normal: "Merriweather Sans",
        italic: "Merriweather Sans",
      },
      300: {
        normal: "Merriweather Sans",
        italic: "Merriweather Sans",
      },
      400: {
        normal: "Merriweather Sans",
        italic: "Merriweather Sans",
      },
      500: {
        normal: "Merriweather Sans",
      },
      600: {
        normal: "Merriweather Sans",
        italic: "Merriweather Sans",
      },
      // Add more variants
      700: {
        normal: 'Merriweather Sans',
      },
      800: {
        normal: 'Merriweather Sans',
        italic: 'Merriweather Sans',
      },
      900: {
        normal: 'Merriweather Sans',
        italic: 'Merriweather Sans',
      },
    },
  },
  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: "Merriweather Sans",
    body: "Merriweather Sans",
    mono: "Merriweather Sans",
  },
  components: {
    Text: {
      defaultProps: {
        fontWeight: 300,
      },
    },
  },
  colors: {
    // Add new color
    primary: {
      500: '#5f9be2',
      600: '#4D90DF',
      700: '#4582c9',
      800: '#3e73b2',
    },
    secondary: {
      500: '#5f9be2',
      600: '#98A2CC',
      700: '#4582c9',
      800: '#3e73b2',
    },
    coolgray: {
      300: '#ADADAD',
    },
  },
  config: {
    // Changing initialColorMode to 'dark'
    initialColorMode: 'light',
  },
});

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <NativeBaseProvider theme={theme}>
        <Loading />
      </NativeBaseProvider>
    );
  }

  return (
    <NativeBaseProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<ProtectedRoute component={Home} />} />
          <Route path={"/assets/:assetId"} element={<ProtectedRoute component={ViewAsset} />} />
          <Route path={"*"} element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </NativeBaseProvider>
  );
}
