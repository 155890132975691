import React, {Component} from 'react';
import {
  Button,
  VStack,
  HStack,
  Text,
  Icon,
  IconButton,
  Box,
  Center,
  Container,
  Heading,
  FormControl,
  Input,
  WarningOutlineIcon,
  Spinner,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from 'native-base';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import {connect} from 'react-redux';
import {parsePhoneNumber} from 'awesome-phonenumber';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faX,
} from '@fortawesome/free-solid-svg-icons';
import numeral from 'numeral';
import {withAuth0} from '@auth0/auth0-react';
import {toast, ToastContainer} from 'react-toastify';
import _ from 'lodash';
import emailValidate from 'email-validator';
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser';
import Decimal from 'decimal.js-light';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
  getTerms,
} from '../util/api';
import {withRouter} from '../util/router';
import {getClient} from '../data/client/actions';
import {createTransfer} from '../data/assets/actions';
import {ExhibitA} from './ExhibitA';

const INITIAL_STATE = {
  showModal: false,
  processing: false,
  loadingTerms: true,
  terms: '',
  termsVersion: '',
  step: '1',
  amount: 0,
  purchasePrice: null,
  purchasePriceDirty: false,
  firstSeller: 5,
  firstSellerDirty: false,
  firstSellerAmount: 0,
  laterOwners: 0,
  laterOwnersDirty: false,
  laterOwnersAmount: 0,
  thirdParties: 0,
  thirdPartiesDirty: 0,
  thirdPartiesAmount: 0,
  serviceFee: 5,
  serviceFeeAmount: 0,
  buyerFirstName: '',
  buyerFirstNameDirty: false,
  buyerLastName: '',
  buyerLastNameDirty: false,
  buyerPhone: '',
  buyerPhoneDirty: false,
  buyerEmail: '',
  buyerEmailDirty: false
};

class SellerCreateTransfer extends Component {
  constructor(props) {
    super(props);

    Modal.setAppElement(document.getElementById('root'));

    const updates = {
      purchasePrice: props.asset.purchaseBasis.value,
    }
    if (props.asset.assetId.previousOwners.length > 0) {
      updates.firstSeller = new Decimal(props.asset.assetId.royalties.firstSeller).times(100).toDecimalPlaces(1).toNumber();
      updates.laterOwners = new Decimal(props.asset.assetId.royalties.laterOwners).times(100).toDecimalPlaces(1).toNumber();
      updates.thirdParties = new Decimal(props.asset.assetId.royalties.thirdParties).times(100).toDecimalPlaces(1).toNumber();
    }

    this.state = {
      ...INITIAL_STATE,
      ...updates,
    };
  }

  reset = () => {
    this.setState(INITIAL_STATE);
  }

  componentDidMount = async () => {
    if (_.isEmpty(this.props.client)) {
      const claims = await this.props.auth0.getIdTokenClaims();
      await this.props.getClient(claims.__raw);
    }
  }

  validPercentageLimit = ({floatValue}) => floatValue < 100 || _.isNil(floatValue);

  createTransfer = async () => {
    this.setState({
      processing: true,
    });
    try {
      const claims = await this.props.auth0.getIdTokenClaims();
      await this.props.createTransfer(
        this.props.asset.id,
        {
          termsVersion: this.state.termsVersion,
          amount: this.state.amount,
          purchasePrice: {
            value: this.state.purchasePrice,
            currency: 'USD'
          },
          purchaseBasis: this.props.asset.purchaseBasis,
          firstSeller: new Decimal(this.state.firstSeller).div(100).toDecimalPlaces(3).toNumber(),
          laterOwners: new Decimal(this.state.laterOwners).div(100).toDecimalPlaces(3).toNumber(),
          thirdParties: new Decimal(this.state.thirdParties).div(100).toDecimalPlaces(3).toNumber(),
          serviceFee: new Decimal(this.state.serviceFee).div(100).toDecimalPlaces(3).toNumber(),
          buyerFirstName: this.state.buyerFirstName,
          buyerLastName: this.state.buyerLastName,
          buyerPhone: this.state.buyerPhone,
          buyerEmail: this.state.buyerEmail,
        },
        claims.__raw,
      );
      this.setState({showModal: false});
      this.props.onSuccess()
    } catch (error) {
      console.error(error);
      toast.error('There was an error processing your request. Please try again later');
    }
    this.setState({
      processing: false,
    });
  }

  isPurchasePriceValid = () => {
    return !_.isNil(this.state.purchasePrice) && _.isFinite(this.state.purchasePrice);
  }

  isFirstSellerValid = () => {
    return !_.isNil(this.state.firstSeller) && _.isFinite(this.state.firstSeller) && this.state.firstSeller >= 0 && this.state.firstSeller < 100;
  }

  isLaterOwnersValid = () => {
    return !_.isNil(this.state.laterOwners) && _.isFinite(this.state.laterOwners) && this.state.laterOwners >= 0 && this.state.laterOwners < 100;
  }

  isBuyerFirstNameValid = () => {
    return !_.isNil(this.state.buyerFirstName) && !_.isEmpty(this.state.buyerFirstName);
  }

  isBuyerLastNameValid = () => {
    return !_.isNil(this.state.buyerLastName) && !_.isEmpty(this.state.buyerLastName);
  }

  isBuyerEmailValid = () => {
    const { user } = this.props.auth0;
    return !_.isNil(this.state.buyerEmail) && !_.isEmpty(this.state.buyerEmail) && emailValidate.validate(this.state.buyerEmail) && user.email !== this.state.buyerEmail;
  }

  isBuyerPhoneValid = () => {
    return !_.isNil(this.state.buyerPhone) && !_.isEmpty(this.state.buyerPhone) && parsePhoneNumber(this.state.buyerPhone, 'US').isValid();
  }

  isValid = () => {
    return this.isPurchasePriceValid() && this.isFirstSellerValid() && this.isLaterOwnersValid() && this.isBuyerFirstNameValid() && this.isBuyerLastNameValid() && this.isBuyerEmailValid() && this.isBuyerPhoneValid();
  }

  renderStep1 = () => {
    const { user } = this.props.auth0;
    if (this.state.step === '1') {
      return (
        <VStack justifyContent={'space-between'} flex={1} overflowY={'scroll'}>
          <HStack mt={5}>
            <VStack flex={1} mx={5} space={3}>
              <Box>
                <Text>Please enter the <Text bold>Buyer information</Text>.</Text>
              </Box>
              <HStack space={3}>
                <FormControl flex={1} mt="3" isInvalid={this.state.buyerFirstNameDirty && !this.isBuyerFirstNameValid()} isRequired={true}>
                  <FormControl.Label>Buyer First Name</FormControl.Label>
                  <Input
                    defaultValue={this.state.buyerFirstName}
                    placeholder={'John'}
                    onChangeText={(val) => {
                      this.setState({
                        buyerFirstNameDirty: true,
                        buyerFirstName: val,
                      });
                    }} />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid buyer first name.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl flex={1} mt="3" isInvalid={this.state.buyerLastNameDirty && !this.isBuyerLastNameValid()} isRequired={true}>
                  <FormControl.Label>Buyer Last Name</FormControl.Label>
                  <Input
                    defaultValue={this.state.buyerLastName}
                    placeholder={'Doe'}
                    onChangeText={(val) => {
                      this.setState({
                        buyerLastNameDirty: true,
                        buyerLastName: val,
                      });
                    }} />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid buyer last name.
                  </FormControl.ErrorMessage>
                </FormControl>
              </HStack>
              <HStack space={3}>
                <FormControl flex={1} mt="3" isInvalid={this.state.buyerPhoneDirty && !this.isBuyerPhoneValid()} isRequired={true}>
                  <FormControl.Label>Buyer Phone Number</FormControl.Label>
                  <Input
                    w={{md: '100%'}}
                    placeholder={'(202) 555-1234'}
                    value={parsePhoneNumber(this.state.buyerPhone, 'US').getNumber('national')}
                    onChangeText={(val) => {
                      this.setState({
                        buyerPhoneDirty: true,
                        buyerPhone: val,
                      });
                    }}
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid buyer phone number.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl flex={1} mt="3" isInvalid={this.state.buyerEmailDirty && !this.isBuyerEmailValid()} isRequired={true}>
                  <FormControl.Label>Buyer Email Address</FormControl.Label>
                  <Input
                    placeholder={'john.doe@email.com'}
                    defaultValue={this.state.buyerEmail}
                    onChangeText={(val) => {
                      this.setState({
                        buyerEmailDirty: true,
                        buyerEmail: val,
                      });
                    }}
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    {this.state.buyerEmail === user.email ? 'You can not transfer an asset to yourself.' : 'Please enter a valid buyer email address.'}
                  </FormControl.ErrorMessage>
                </FormControl>
              </HStack>
            </VStack>
            <VStack flex={1} mx={5} space={3}>
              <Box>
                <Text>Enter the <Text bold>Purchase Price</Text> the <Text bold>Buyer</Text> will pay for the <Text bold>Asset</Text> '{this.props.asset.assetId.assetName}':</Text>
                <FormControl mt="2" isInvalid={this.state.purchasePriceDirty && !this.isPurchasePriceValid()} isRequired={true}>
                  <FormControl.Label>Purchase Price</FormControl.Label>
                  <InputGroup w={{
                    base: "70%",
                    md: "285"
                  }}>
                    <InputLeftAddon children={"$"} />
                    <NumberFormat thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} value={this.state.purchasePrice === '' || _.isNil(this.state.purchasePrice) ? '' : numeral(this.state.purchasePrice).format('0,0.00')} placeholder="123.45" customInput={Input} onValueChange={(val) => {
                      this.setState({
                        purchasePriceDirty: true,
                        purchasePrice: val.floatValue,
                      });
                    }} />
                  </InputGroup>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Purchase Price.
                  </FormControl.ErrorMessage>
                </FormControl>
              </Box>
              <Box mt={6}>
                <Text>Enter the <Text bold>Royalty</Text> percentage you will earn on future <Text bold>Purchase Prices</Text> whenever this <Text bold>Asset</Text> is resold using CopyForward:</Text>
                <FormControl mt="2" isInvalid={this.state.firstSellerDirty && !this.isFirstSellerValid()} isRequired={true} isDisabled={this.props.asset.assetId.previousOwners.length > 0}>
                  <FormControl.Label>First Seller Royalty</FormControl.Label>
                  <InputGroup w={{
                    base: "70%",
                    md: "285"
                  }}>
                    <NumberFormat width={'50px'} decimalScale={1} isAllowed={this.validPercentageLimit} defaultValue={this.state.firstSeller} placeholder="5" customInput={Input} onValueChange={(val) => {
                      this.setState({
                        firstSellerDirty: true,
                        firstSeller: val.floatValue || '',
                      });
                    }} />
                    <InputRightAddon children={"%"} />
                  </InputGroup>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Percentage between 0 and 99.
                  </FormControl.ErrorMessage>
                </FormControl>
              </Box>
              <Box mt={6}>
                <Text>Enter the <Text bold>Royalty</Text> percentage <Text bold>Later Owners</Text> will share equally whenever this <Text bold>Asset</Text> is resold using CopyForward:</Text>
                <FormControl mt="2" isInvalid={this.state.laterOwnersDirty && !this.isLaterOwnersValid()} isRequired={true} isDisabled={this.props.asset.assetId.previousOwners.length > 0}>
                  <FormControl.Label>Later Owners Royalty</FormControl.Label>
                  <InputGroup w={{
                    base: "70%",
                    md: "285"
                  }}>
                    <NumberFormat width={'50px'} decimalScale={1} isAllowed={this.validPercentageLimit} defaultValue={this.state.laterOwners} placeholder="5" customInput={Input} onValueChange={(val) => {
                      this.setState({
                        laterOwnersDirty: true,
                        laterOwners: val.floatValue || '',
                      });
                    }} />
                    <InputRightAddon children={"%"} />
                  </InputGroup>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Percentage between 0 and 99.
                  </FormControl.ErrorMessage>
                </FormControl>
              </Box>
              <Box mt={6}>
                <Text>The <Text bold>Service Fee</Text> is a standard fixed rate:</Text>
                <FormControl mt="2" isDisabled={true}>
                  <FormControl.Label>Service Fee %</FormControl.Label>
                  <InputGroup w={{
                    base: "70%",
                    md: "285"
                  }}>
                    <NumberFormat width={'50px'} value={this.state.serviceFee} decimalScale={1} maxLength={2} thousandSeparator={true} placeholder="5" customInput={Input} />
                    <InputRightAddon children={"%"} />
                  </InputGroup>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Percentage between 0 and 99.
                  </FormControl.ErrorMessage>
                </FormControl>
              </Box>
            </VStack>
          </HStack>
          <HStack mt={5} pt={5} pb={5} space={"md"} justifyContent={"flex-end"}>
            <Button variant={"outline"} onPress={() => {
              this.reset();
              this.setState({ showModal: false });
            }}>Cancel</Button>
            <Button
              isLoading={this.state.processing}
              isLoadingText={"Next"}
              isDisabled={this.state.processing || !this.isValid()}
              onPress={async () => {
                const serviceFeeAmount = new Decimal(this.state.purchasePrice).times(this.state.serviceFee).div(100).toDecimalPlaces(2).toNumber();
                const firstSellerAmount = new Decimal(this.state.purchasePrice).times(this.props.asset.assetId.previousOwners.length > 0 ? this.state.firstSeller : 0).div(100).toDecimalPlaces(3).toNumber();
                const laterOwnersAmount = new Decimal(this.state.purchasePrice).times(this.props.asset.assetId.previousOwners.length > 0 ? this.state.laterOwners : 0).div(100).toDecimalPlaces(3).toNumber();
                const thirdPartiesAmount = new Decimal(this.state.purchasePrice).times(this.props.asset.assetId.previousOwners.length > 0 ? this.state.thirdParties : 0).div(100).toDecimalPlaces(3).toNumber();
                this.setState({
                  firstSellerAmount,
                  laterOwnersAmount,
                  thirdPartiesAmount,
                  serviceFeeAmount,
                  amount: new Decimal(this.state.purchasePrice).plus(serviceFeeAmount).plus(firstSellerAmount).plus(laterOwnersAmount).plus(thirdPartiesAmount).toDecimalPlaces(2).toNumber(),
                  step: '2'
                })
              }}
            >
              Next
            </Button>
          </HStack>
        </VStack>
      )
    }
  }

  renderStep2 = () => {
    const preExpanded = ['exhibitA'];
    if (!_.has(this.props.client, 'lastSellerTermsViewed') || this.props.client.lastSellerTermsViewed !== this.state.termsVersion) {
      preExpanded.push('terms');
    }
    if (this.state.step === '2') {
      return (
        <VStack flex={1} justifyContent={'space-between'}  overflowY={'hidden'}>
          <Accordion allowMultipleExpanded={true} preExpanded={preExpanded}>
            <AccordionItem uuid={'terms'}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Terms and Conditions
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {ReactHtmlParser(this.state.terms)}
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'exhibitA'}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Exhibit A
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <VStack>
                  <ExhibitA
                    seller={this.props.client}
                    buyer={{firstName: this.state.buyerFirstName, lastName: this.state.buyerLastName, phone: this.state.buyerPhone, email: this.state.buyerEmail}}
                    asset={this.props.asset.assetId}
                    purchasePrice={this.state.purchasePrice}
                    serviceFee={{percent: this.state.serviceFee/100, amount: this.state.serviceFeeAmount}}
                    royalties={{
                      firstSeller: {
                        percent: this.state.firstSeller/100,
                        amount: this.state.firstSellerAmount,
                      },
                      laterOwners: {
                        percent: this.state.laterOwners/100,
                        amount: this.state.laterOwnersAmount,
                      },
                      thirdParties: {
                        percent: this.state.thirdParties/100,
                        amount: this.state.thirdPartiesAmount,
                      },
                    }}
                    amount={this.state.amount}
                  />
                </VStack>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <Center paddingBottom={5} paddingTop={5}>
            <Button isLoading={this.state.processing} isLoadingText={"Agree and Sign"} isDisabled={this.state.processing} onPress={() => this.createTransfer()}>Agree and Sign</Button>
          </Center>
        </VStack>
      )
    }
  }

  render() {
    return (
      <Container>
        <Button
          onPress={() => {
            this.setState({
              showModal: !this.state.showModal,
            });
          }}
        >Transfer</Button>
        <Modal
          isOpen={this.state.showModal}
          onAfterOpen={async () => {
            try {
              this.setState({ loadingTerms: true });
              const claims = await this.props.auth0.getIdTokenClaims();
              const terms = await getTerms(claims.__raw);
              this.setState({
                loadingTerms: false,
                terms: terms.data.terms,
                termsVersion: terms.data.version,
              });
            } catch (error) {
              console.error(error);
            }
          }}
          style={
            {
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
              },
              content: {
                margin: 'auto',
                maxWidth: '75%',
                maxHeight: '90%',
                minHeight: '90%',
                borderRadius: 6,
              }
            }
          }
        >
          {this.state.paypalClientToken === '' && this.state.terms === '' && (
            <Center height={'100%'} width={'100%'}>
              <HStack space={3}>
                <Spinner accessibilityLabel="Loading" color={"primary.600"} />
                <Heading color="primary.600" fontSize="md">
                  Loading
                </Heading>
              </HStack>
            </Center>
          )}
          {this.state.paypalClientToken !== '' && this.state.terms !== '' && (
            <VStack h={"100%"} maxH={"100%"}>
              <HStack justifyContent={'space-between'} mb={3}>
                <Heading size={"lg"}>
                  Transfer your asset
                </Heading>
                <IconButton icon={<Icon as={FontAwesomeIcon} icon={faX} size={"md"} />} onPress={() => {
                  this.reset();
                  this.setState({
                    showModal: !this.state.showModal,
                  });
                }} />
              </HStack>
              <HStack>
                <HStack flex={1}>
                  <VStack alignItems={'flex-start'} flex={1}>
                    <Box width={'100%'} flex={1} p={2}>
                      <Text fontSize={'md'} fontWeight={'medium'}>{this.props.asset.assetId.assetName}</Text>
                      <Text fontSize={'xs'} isTruncated lineHeight={'md'}>Asset ID: {this.props.asset.assetId.assetId}</Text>
                      <Center mt={4}>
                        <img
                          src={!_.isNil(this.props.asset.assetId.assetImages) && _.isArray(this.props.asset.assetId.assetImages) ? this.props.asset.assetId.assetImages[0].signedUrl : ''}
                          style={styles.image}
                          alt={this.props.asset.assetId.assetName || 'Unknown'}
                        />
                      </Center>
                    </Box>
                  </VStack>
                </HStack>
                <HStack flex={2}>
                  {this.renderStep1()}
                  {this.renderStep2()}
                </HStack>
              </HStack>
            </VStack>
          )}
          <ToastContainer
            position={"top-center"}
            theme={"colored"}
            pauseOnFocusLoss={false}
          />
        </Modal>
      </Container>
    );
  }
}

const styles = {
  image: {
    display: 'block',
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '200px',
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client
  };
};

export default connect(
    mapStateToProps,
    {getClient, createTransfer}
)(withAuth0(withRouter(SellerCreateTransfer)));
