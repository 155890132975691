import axios from 'axios';
import _ from 'lodash';

const _delete = (url, {secured = false, token = null}) => {
  const config = {};
  if (secured) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  return axios.delete(url, config);
}

const _get = (url, {secured = false, token = null}) => {
  const config = {};
  if (secured) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  return axios.get(url, config);
}

const _patch = (url, body, {secured = false, token = null, headers = null}) => {
  const config = {};
  if (secured) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  if (!_.isNil(headers)) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios.patch(url, body, config);
}

const _post = (url, body, {secured = false, token = null, headers = null}) => {
  const config = {};
  if (secured) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  if (!_.isNil(headers)) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios.post(url, body, config);
}

const _put = (url, body, {secured = false, token = null, headers = null}) => {
  const config = {};
  if (secured) {
    config.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  if (!_.isNil(headers)) {
    config.headers = {
      ...config.headers,
      ...headers,
    }
  }

  return axios.put(url, body, config);
}

export const createUser = (data, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me`, data, {secured: true, token});
}

export const updateUser = (data, token) => {
  return _patch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me`, data, {secured: true, token});
}

export const getUser = (token) => {
  return _get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me`, {secured: true, token});
}

export const addAsset = (asset, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/assets`, asset, {secured: true, token});
}

export const addAssetImage = async (assetId, image, token) => {
  const url = await _post(
`${process.env.REACT_APP_BACKEND_ENDPOINT}/assets/${assetId}/images`,
    {
      extension: image.name.split('.').pop(),
      contentType: image.type
    },
    {
      secured: true,
      token
    }
  );
  await _put(
    url.data.signedRequest,
    image,
    {
      secured: false,
      headers: {
        'Content-Type': image.type,
      },
    }
  );
  return {
    filename: url.data.key,
    contentType: image.type,
  };
}

export const getAssets = async (token) => {
  return await _get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/assets`, {secured: true, token});
}

export const deleteAsset = (assetId, token) => {
  return _delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/assets/${assetId}`, {secured: true, token});
}

export const getAsset = (assetId, token) => {
  return _get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/assets/${assetId}`, {secured: true, token});
}

export const getAssetTransfers = (assetId, token) => {
  return _get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/assets/${assetId}/transfers`, {secured: true, token});
}

export const updateAsset = (assetId, asset, token) => {
  return _patch(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/assets/${assetId}`, asset, {secured: true, token});
}

export const getTerms = (token, version = null) => {
  if (_.isNil(version)) {
    return _get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/terms`, {secured: true, token});
  }
  return _get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/terms/${version}`, {secured: true, token});
}

export const createTransfer = (assetId, data, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/assets/${assetId}/transfer`, data, {secured: true, token});
}

export const cancelTransfer = (transferId, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/transfers/${transferId}/cancel`, {}, {secured: true, token});
}

export const completeTransfer = (transferId, data, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/transfers/${transferId}/complete`, data, {secured: true, token});
}

export const completePayment = (transferId, data, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/me/transfers/${transferId}/payment`, data, {secured: true, token});
}

export const getPayPalClientToken = (token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/paypal/clientToken`, {}, {secured: true, token});
}

export const createPayPalOrder = ({amount, transferId}, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/paypal/order`, {amount, transferId}, {secured: true, token});
}

export const capturePayPalOrder = ({orderId}, token) => {
  return _post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/paypal/order/${orderId}/capture`, {}, {secured: true, token});
}
