import React, {useState} from 'react';
import Modal from 'react-modal';
import {
  Button,
  FormControl,
  HStack,
  Input,
  Text,
  VStack,
  WarningOutlineIcon,
} from 'native-base';
import NumberFormat from 'react-number-format';
import {toast, ToastContainer} from 'react-toastify';
import _ from 'lodash';
import { parsePhoneNumber } from 'awesome-phonenumber';
import {connect, useDispatch, useSelector} from 'react-redux';

import {saveClient, updateClient} from '../data/client/actions';
import {useAuth0} from '@auth0/auth0-react';
import spacetime from 'spacetime';

const Profile = ({showModal, setShowModal, onClose}) => {
  const dispatch = useDispatch();
  const { getIdTokenClaims } = useAuth0();
  const client = useSelector(state => state.data.client);
  const [firstName, setFirstName] = useState(null);
  const [firstNameDirty, setFirstNameDirty] = useState(false);
  const [lastName, setLastName] = useState(null);
  const [lastNameDirty, setLastNameDirty] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberDirty, setPhoneNumberDirty] = useState(false);
  const [processing, setProcessing] = useState(false);

   const isFirstNameInvalid = () => {
    return _.isNil(firstName) || _.isEmpty(firstName);
  }

  const isLastNameInvalid = () => {
    return _.isNil(lastName) || _.isEmpty(lastName);
  }

  const isPhoneNumberInvalid = () => {
    if (!_.isNil(phoneNumber)) {
      return !parsePhoneNumber(phoneNumber, 'US').isValid();
    }
    return false;
  }

  const isInvalid = () => {
    return !firstNameDirty || isFirstNameInvalid() || !lastNameDirty || isLastNameInvalid() || !phoneNumberDirty || isPhoneNumberInvalid();
  }

  return (
    <Modal
      isOpen={showModal}
      onAfterOpen={() => {
        if (!_.isEmpty(client)) {
          if (_.has(client, 'firstName') && !_.isNil(client.firstName) && !_.isEmpty(client.firstName)) {
            setFirstName(client.firstName);
            setFirstNameDirty(true);
          }
          if (_.has(client, 'lastName') && !_.isNil(client.lastName) && !_.isEmpty(client.lastName)) {
            setLastName(client.lastName);
            setLastNameDirty(true);
          }
          if (_.has(client, 'phone') && !_.isNil(client.phone) && !_.isEmpty(client.phone)) {
            setPhoneNumber(parsePhoneNumber(client.phone, 'US').getNumber('significant'));
            setPhoneNumberDirty(true);
          }
        }
      }}
      onRequestClose={() => {
        setFirstNameDirty(null);
        setFirstNameDirty(false);
        setLastName(null);
        setLastNameDirty(false);
        setPhoneNumber(null);
        setPhoneNumberDirty(false);
      }}
      style={
        {
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            margin: 'auto',
            maxWidth: '30%',
            height: 'fit-content',
          }
        }
      }
    >
      <VStack>
        <HStack>
          <Text fontSize={"2xl"} fontWeight={"bold"}>
            Please complete your profile
          </Text>
        </HStack>
        <HStack>
          <VStack flex={1} pr={3} mt={3}>
            <Text>
              Thank you for joining CopyForward! Please take a moment to complete your profile before continuing.
            </Text>
            <FormControl mt="3" isInvalid={firstNameDirty && isFirstNameInvalid()} isRequired={true}>
              <FormControl.Label>First Name</FormControl.Label>
              <Input
                defaultValue={firstName}
                onChangeText={(val) => {
                  setFirstNameDirty(true);
                  setFirstName(val);
                }}
              />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please enter a valid first name.
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl mt="3" isInvalid={lastNameDirty && isLastNameInvalid()} isRequired={true}>
              <FormControl.Label>Last Name</FormControl.Label>
              <Input
                defaultValue={lastName}
                onChangeText={(val) => {
                  setLastNameDirty(true);
                  setLastName(val);
                }}
              />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please enter a valid last name.
              </FormControl.ErrorMessage>
            </FormControl>
            <FormControl mt="3" isInvalid={phoneNumberDirty && isPhoneNumberInvalid()} isRequired={true}>
              <FormControl.Label>Mobile Phone Number</FormControl.Label>
              <NumberFormat value={phoneNumber} format="+1 (###) ###-####" placeholder="+1 (555) 123-1234" customInput={Input} onValueChange={(val) => {
                setPhoneNumberDirty(true);
                setPhoneNumber(val.value);
              }} />
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please enter a valid mobile phone number.
              </FormControl.ErrorMessage>
            </FormControl>
          </VStack>
        </HStack>
        <HStack mt={5} space={"md"} justifyContent={"flex-end"}>
          <Button
            isLoading={processing}
            isLoadingText={"Continue"}
            isDisabled={processing || isInvalid()}
            onPress={async () => {
              setProcessing(true);
              const claims = await getIdTokenClaims();
              if (_.has(client, 'clientId')) {
                const data = {
                  firstName,
                  lastName,
                  phone: parsePhoneNumber(phoneNumber, 'US').getNumber(),
                }
                if (!_.has(client, 'accountOpened')) {
                  data.accountOpened = spacetime.now('America/New_York').format('iso');
                }
                if (!_.has(client, 'termsAgreed')) {
                  data.termsAgreed = spacetime.now('America/New_York').format('iso');
                }
                dispatch(updateClient(
                  data,
                  claims.__raw
                ))
                .then(() => {
                  setProcessing(false);
                  onClose();
                  setShowModal(false);
                })
                .catch(error => {
                  console.error(error);
                  setProcessing(false);
                  toast.error("There was an error processing your request.");
                });
              } else {
                dispatch(saveClient(
                  {
                    firstName,
                    lastName,
                    phone: parsePhoneNumber(phoneNumber, 'US').getNumber(),
                    accountOpened: spacetime.now('America/New_York').format('iso'),
                    termsAgreed: spacetime.now('America/New_York').format('iso'),
                  },
                  claims.__raw
                ))
                  .then(() => {
                    setProcessing(false);
                    onClose();
                    setShowModal(false);
                  })
                  .catch(error => {
                    console.error(error);
                    setProcessing(false);
                    toast.error("There was an error processing your request.");
                  });
              }
            }}
          >
            Continue
          </Button>
        </HStack>
      </VStack>
      <ToastContainer
          position={"top-center"}
          theme={"colored"}
          pauseOnFocusLoss={false}
      />
    </Modal>
  )
};

const mapStateToProps = () => {
  return {};
};

export default connect(
    mapStateToProps,
    {
      saveClient,
    }
)(Profile);
