import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Box,
  Button,
  Center, Divider,
  Heading,
  HStack, Icon, IconButton,
  Image,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import _ from 'lodash';
import {withAuth0} from '@auth0/auth0-react';
import {ToastContainer} from 'react-toastify';
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX, faFileLines} from '@fortawesome/free-solid-svg-icons';
import numeral from 'numeral';
import spacetime from 'spacetime';
import ReactHtmlParser from 'react-html-parser';

import {getAssetTransfers, getTerms} from '../util/api';
import {withRouter} from '../util/router';
import {ExhibitA} from './ExhibitA';
import AssetImage from './AssetImage';
import {stripHtml} from 'string-strip-html';

class ViewButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingTerms: false,
      showTermsModal: false,
    }
  }

  render = () => {
    if (_.isNil(this.props.row) || this.props.row.status !== 'COMPLETE') {
      return;
    }
    return (
      <>
        <IconButton icon={<Icon as={FontAwesomeIcon} icon={faFileLines} size={"md"} />} onPress={() => {
          this.setState({
            showTermsModal: !this.state.showTermsModal,
            loadingTerms: true,
          });
        }} />
        <Modal
          isOpen={this.state.showTermsModal}
          onAfterOpen={async () => {
            try {
              this.setState({ loadingTerms: true });
              const claims = await this.props.auth0.getIdTokenClaims();
              const terms = await getTerms(claims.__raw, this.props.row.sellerTermsAndConditionsVersion);
              this.setState({
                loadingTerms: false,
                terms: terms.data.terms,
                termsVersion: terms.data.version,
              });
            } catch (error) {
              console.error(error);
            }
          }}
          onRequestClose={() => this.setState({loadingTerms: true, showTermsModal: false})}
          style={
            {
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
              },
              content: {
                margin: 'auto',
                maxWidth: '50%',
                maxHeight: '75%',
                minHeight: '75%',
                borderRadius: 6,
              }
            }
          }
        >
          {this.state.loadingTerms && (
            <Center height={'100%'} width={'100%'}>
              <HStack space={3}>
                <Spinner accessibilityLabel="Loading" color={"primary.600"} />
                <Heading color="primary.600" fontSize="md">
                  Loading
                </Heading>
              </HStack>
            </Center>
          )}
          {!this.state.loadingTerms && (
            <VStack h={"100%"} maxH={"100%"}>
              <HStack justifyContent={'space-between'} mb={3}>
                <Heading size={"lg"}>
                  Terms and Conditions
                </Heading>
                <IconButton icon={<Icon as={FontAwesomeIcon} icon={faX} size={"md"} />} onPress={() => {
                  this.setState({
                    showTermsModal: !this.state.showTermsModal,
                  });
                }} />
              </HStack>
              <VStack>
                <VStack space={3} height={'300px'} overflowY={"scroll"}>
                  <Box flex={1} overflowY={"scroll"} className={"frame"}>
                    <div style={{overflowY: "auto"}} className={"frame"}>
                      {ReactHtmlParser(this.state.terms)}
                    </div>
                  </Box>
                </VStack>
                <VStack mb={5}>
                  <Divider width={'75%'} alignSelf={'center'} mt={3} />
                  <Box>
                    <ExhibitA
                      seller={this.props.row.sellerId}
                      buyer={this.props.row.buyerId}
                      asset={this.props.row.assetId}
                      purchasePrice={this.props.row.purchasePrice.value}
                      serviceFee={{percent: this.props.row.serviceFee, amount: this.props.row.serviceFeeAmount.value}}
                      royalties={{
                        firstSeller: {
                          percent: this.props.row.royalties.firstSeller,
                          amount: this.props.row.royaltiesAmount.firstSeller.value
                        },
                        laterOwners: {
                          percent: this.props.row.royalties.laterOwners,
                          amount: this.props.row.royaltiesAmount.laterOwners.value,
                        },
                        thirdParties: {
                          percent: this.props.row.royalties.thirdParties,
                          amount: this.props.row.royaltiesAmount.thirdParties.value,
                        },
                      }}
                      amount={this.props.row.amount}
                    />
                  </Box>
                </VStack>
              </VStack>
            </VStack>
          )}
          <ToastContainer
            position={"top-center"}
            theme={"colored"}
            pauseOnFocusLoss={false}
          />
        </Modal>
      </>
    )
  }
}

class TermsButton extends Component {
  constructor(props) {
    super(props);

    Modal.setAppElement(document.getElementById('root'));

    this.state = {
      loadingHistory: false,
      loadingTerms: true,
      showModal: false,
      showTermsModal: false,
      asset: this.props.asset,
      history: [],
    }
  }

  render() {
    const columns = [
      {
        name: 'Owner',
        cell: row => row.ownerName,
      },
      {
        name: 'Acquired Date',
        selector: row => row.dateAcquired,
        format: row => spacetime(row.dateAcquired, 'America/New_York').format('numeric-us'),
      },
      {
        name: 'Purchase Price',
        selector: row => _.has(row, 'purchaseBasis') ? row.purchaseBasis.value : 'N/A',
        format: row => _.has(row, 'purchaseBasis') ? numeral(row.purchaseBasis.value).format('$0,0.00') : 'N/A',
      },
      {
        name: 'Sold Date',
        selector: row => row.dateSold,
        format: row => row.ownershipStatus === 'SOLD' ? spacetime(row.dateSold, 'America/New_York').format('numeric-us') : 'N/A',
      },
      {
        name: 'Sold Price',
        selector: row => _.has(row, 'purchasePrice') ? row.purchasePrice.value : 'N/A',
        format: row => _.includes(['SOLD', 'PENDING_TRANSFER', 'PENDING_PAYMENT'], row.ownershipStatus) && _.has(row, 'purchasePrice') ? numeral(row.purchasePrice.value).format('$0,0.00') : 'N/A',
      },
      {
        name: 'Royalties',
        selector: row => _.has(row, 'transferredFromId') ? row.transferredFromId.totalRoyalties : 'N/A',
        format: row => row.ownershipStatus === 'SOLD' && _.has(row, 'transferredFromId') ? numeral(row.transferredFromId.totalRoyalties).format('$0,0.00') : 'N/A',
      },
      {
        name: 'Royalties Earned',
        selector: row => row.royaltiesEarned,
        format: row => row.ownershipStatus === 'SOLD' ? numeral(row.royaltiesEarned).format('$0,0.00') : 'N/A',
      },
      {
        name: 'Terms and Conditions',
        cell: row => <ViewButton row={row.transferredToId} client={this.props.client} auth0={this.props.auth0} />,
        center: true,
      },
    ];

    return (
      <>
        <Button variant={'outline'} onPress={() => this.setState({showModal: true})}>
          <HStack alignItems={'center'}>
            {_.has(this.props.asset.assetId, 'previousOwners') && this.props.asset.assetId.previousOwners.length > 0 && <Image size={'1.5em'} mr={1} source={{uri: 'https://cdn.copyforward.com/icon.png'}} />}
            <Text>Terms</Text>
          </HStack>
        </Button>
        <Modal
          isOpen={this.state.showModal}
          onAfterOpen={async () => {
            try {
              this.setState({
                loadingHistory: true
              });
              const claims = await this.props.auth0.getIdTokenClaims();
              const {data} = await getAssetTransfers(this.state.asset.assetId.assetId, claims.__raw);
              this.setState({
                loadingHistory: false,
                history: data,
              });
            } catch (error) {
              console.error(error);
              this.setState({
                loadingHistory: false,
                showModal: false,
              });
            }
          }}
          onRequestClose={() => this.props.updateAsset(this.state.asset)}
          style={
            {
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
              },
              content: {
                margin: 'auto',
                maxWidth: '75%',
                maxHeight: '90%',
                minHeight: '90%',
                borderRadius: 6,
              }
            }
          }
        >
          {this.state.loadingHistory && (
            <Center height={'100%'} width={'100%'}>
              <HStack space={3}>
                <Spinner accessibilityLabel="Loading" color={"primary.600"} />
                <Heading color="primary.600" fontSize="md">
                  Loading
                </Heading>
              </HStack>
            </Center>
          )}
          {!this.state.loadingHistory && (
            <VStack h={"100%"} maxH={"100%"}>
              <HStack justifyContent={'space-between'} mb={3}>
                <Heading size={"lg"}>
                  Asset History
                </Heading>
                <IconButton icon={<Icon as={FontAwesomeIcon} icon={faX} size={"md"} />} onPress={() => {
                  this.setState({
                    showModal: !this.state.showModal,
                  });
                }} />
              </HStack>
              <HStack space={3}>
                <AssetImage style={{height: 100}} image={!_.isNil(this.state.asset.assetId.assetImages) && _.isArray(this.state.asset.assetId.assetImages) ? this.state.asset.assetId.assetImages[0].signedUrl : ''} alt={this.state.asset.assetId.assetName} ready={true} />
                <VStack space={1}>
                  <Heading size={'sm'}>Asset name: {this.state.asset.assetId.assetName}</Heading>
                  <Text><Text fontWeight={'500'}>Creator:</Text> {this.state.asset.assetId.creatorName}</Text>
                  <Text><Text fontWeight={'500'}>Description:</Text> {_.truncate(stripHtml(this.state.asset.assetId.assetDescription).result, {length: 100})}</Text>
                </VStack>
              </HStack>
              <VStack>
                <DataTable
                  pagination
                  progressPending={this.state.loadingHistory}
                  striped
                  fixedHeader
                  columns={columns}
                  data={this.state.history}
                />
              </VStack>
            </VStack>
          )}
          <ToastContainer
            position={"top-center"}
            theme={"colored"}
            pauseOnFocusLoss={false}
          />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client
  };
};

export default connect(
  mapStateToProps,
  {},
)(withAuth0(withRouter(TermsButton)));
