import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import _ from 'lodash';
import {Box, Center} from 'native-base';

export default function AssetImage({image, alt, ready = false, onLoad = () => {}, key, style = {}, ...rest}) {
  return (
    <ReactPlaceholder key={key} type={"rect"} showLoadingAnimation={!ready} style={styles.placeholder} ready={!_.isNil(image) && !_.isEmpty(image) && ready}>
      <Center>
        <Box display={"inline-block"}>
          <img
            src={image}
            style={{...styles.image, ...style}}
            alt={alt || 'Unknown'}
            onLoad={onLoad}
            {...rest}
          />
        </Box>
      </Center>
    </ReactPlaceholder>
  )
}

const styles = {
  placeholder: {
    height: 200,
    width: "100%",
  },
  image: {
    display: 'block',
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '200px',
    borderWidth: 1,
  }
}