import React, {Component} from 'react';
import {
  FormControl,
  Input,
  Button,
  VStack,
  HStack,
  Text,
  WarningOutlineIcon,
} from 'native-base';
import Modal from 'react-modal';
import {withAuth0} from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';

import {connect} from 'react-redux';
import {cancelTransfer} from '../data/assets/actions';
import {withRouter} from '../util/router';

const INIT_STATE = {
  showModal: false,
  cancelConfirm: '',
  cancelConfirmDirty: false,
  processing: false,
}

const CANCEL_CONFIRM = 'cancel';

class CancelTransferButton extends Component {
  constructor(props) {
    super(props);

    Modal.setAppElement(document.getElementById('root'));

    this.state = INIT_STATE;
  }

  reset = () => {
    this.setState(INIT_STATE);
  }

  isInvalid = () => {
    return this.state.cancelConfirm !== CANCEL_CONFIRM;
  }

  isDeleteConfirmInvalid = () => {
    return _.isNil(this.state.cancelConfirm) || _.isEmpty(_.trim(this.state.cancelConfirm));
  }

  render() {
    return (
      <>
        <Button
          variant={"outline"}
          onPress={() => {
            this.setState({showModal: !this.state.showModal});
          }}
        >Cancel</Button>
        <Modal
          isOpen={this.state.showModal}
          style={
            {
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
              },
              content: {
                margin: 'auto',
                maxWidth: '25%',
                height: 'fit-content',
              }
            }
          }
        >
          <VStack>
            <HStack>
              <Text fontSize={"2xl"} fontWeight={"bold"}>
                Cancel transfer of "{this.props.asset.assetId.assetName}"
              </Text>
            </HStack>
            <VStack flex={1} pr={3} mt={3}>
              <Text>Please enter <Text bold>cancel</Text> to cancel this transfer.</Text>
              <FormControl mt="3" isInvalid={this.state.cancelConfirmDirty && this.isDeleteConfirmInvalid()} isRequired={true}>
                <FormControl.Label>Confirm</FormControl.Label>
                <Input
                  placeholder={'cancel'}
                  onChangeText={(val) => {
                    this.setState({
                      cancelConfirmDirty: true,
                      cancelConfirm: val,
                    });
                  }}
                />
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                  Please confirm your intent to cancel this transfer.
                </FormControl.ErrorMessage>
              </FormControl>
            </VStack>
            <HStack mt={5} space={"md"} justifyContent={"flex-end"}>
              <Button variant={"outline"} onPress={() => {
                this.reset();
                this.setState({showModal: false});
              }}>Cancel</Button>
              <Button
                isLoading={this.state.processing}
                isLoadingText={"Confirm"}
                isDisabled={this.state.processing || this.isInvalid()}
                onPress={async () => {
                  this.setState({processing: true});
                  try {
                    const claims = await this.props.auth0.getIdTokenClaims();
                    await this.props.cancelTransfer(this.props.transferId, this.props.side, claims.__raw);
                    this.reset();
                    this.props.onSuccess();
                  } catch (_error) {
                    this.props.onError();
                    toast.error(this.props.errorMessage || "There was an error processing your request.");
                  } finally {
                    this.setState({processing: false, showModal: false});
                  }
                }}
              >
                Confirm
              </Button>
            </HStack>
          </VStack>
          <ToastContainer
            position={"top-center"}
            theme={"colored"}
            pauseOnFocusLoss={false}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client,
    errorMessage: data.assets.errorMessage,
  };
};

export default connect(
    mapStateToProps,
    {cancelTransfer}
)(withAuth0(withRouter(CancelTransferButton)));
