import React, {Component} from 'react';
import {
  FormControl,
  Input,
  Button,
  VStack,
  HStack,
  Text,
  InputGroup,
  InputLeftAddon,
  WarningOutlineIcon,
} from 'native-base';
import Modal from 'react-modal';
import numeral from 'numeral';
import NumberFormat from 'react-number-format';
import JoditEditor from "jodit-react";
import {withAuth0} from '@auth0/auth0-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import spacetime from 'spacetime';
import _ from 'lodash';

import AddImage from './AddImage';
import {addAssetImage} from '../util/api';
import {connect} from 'react-redux';
import {withRouter} from '../util/router';
import {updateAsset} from '../data/assets/actions';
import ReactHtmlParser from 'react-html-parser';

const INIT_STATE = {
  showModal: false,
  description: null,
  name: null,
  nameDirty: false,
  purchaseBasis: null,
  purchaseBasisDirty: false,
  creatorName: null,
  creatorNameDirty: false,
  dateAcquired: null,
  dateAcquiredDirty: false,
  files: [],
  filesDirty: false,
  asset: null,
  processing: false,
}

class EditAsset extends Component {
  constructor(props) {
    super(props);

    Modal.setAppElement(document.getElementById('root'));

    this.editor = React.createRef()

    this.state = {
      ...INIT_STATE,
      asset: props.asset,
      description: '',
      origDescription: props.asset.assetId.assetDescription,
      name: props.asset.assetId.assetName,
      purchaseBasis: props.asset.purchaseBasis.value,
      creatorName: props.asset.assetId.creatorName,
      dateAcquired: props.asset.dateAcquired,
      files: _.map(props.asset.assetId.assetImages, file => { return { preview: file.signedUrl, name: props.asset.assetId.assetName } })
    }
  }

  reset = () => {
    this.setState(INIT_STATE);
  }

  isDirty = () => {
    return this.state.descriptionDirty || this.state.creatorNameDirty || this.state.nameDirty || this.state.purchaseBasisDirty || this.state.dateAcquiredDirty || this.state.filesDirty;
  }

  isInvalid = () => {
    return (this.state.descriptionDirty && this.isDescriptionInvalid()) ||
      (this.state.creatorNameDirty && this.isCreatorNameInvalid()) ||
      (this.state.nameDirty && this.isNameInvalid()) ||
      (this.state.purchaseBasisDirty && this.isPurchaseBasisInvalid()) ||
      (this.state.dateAcquiredDirty && this.isDateAcquiredInvalid()) ||
      (this.state.filesDirty && this.isFilesInvalid());
  }

  isDescriptionInvalid = () => {
    return this.state.descriptionDirty && (_.isNil(this.state.description) || _.isEmpty(this.state.description));
  }

  isNameInvalid = () => {
    return _.isNil(this.state.name) || _.isEmpty(this.state.name);
  }

  isPurchaseBasisInvalid = () => {
    return _.isNil(this.state.purchaseBasis) || _.isEmpty(this.state.purchaseBasis) || !_.has(this.state.purchaseBasis, 'floatValue') || _.isNil(this.state.purchaseBasis.floatValue) || this.state.purchaseBasis.floatValue <= 0;
  }

  isCreatorNameInvalid = () => {
    return _.isNil(this.state.creatorName) || _.isEmpty(this.state.creatorName);
  }

  isDateAcquiredInvalid = () => {
    return _.isNil(this.state.dateAcquired) || _.isEmpty(this.state.dateAcquired) || !spacetime(this.state.dateAcquired.formattedValue).isValid();
  }

  isFilesInvalid = () => {
    return this.state.files.length !== 1;
  }

  render() {
    return (
      <>
        <Button
          variant={"outline"}
          onPress={() => {
            this.setState({
              showModal: !this.state.showModal,
              asset: this.props.asset,
              description: '',
              origDescription: this.props.asset.assetId.assetDescription,
              name: this.props.asset.assetId.assetName,
              purchaseBasis: numeral(this.props.asset.purchaseBasis.value).format('0,0.00'),
              creatorName: this.props.asset.assetId.creatorName,
              dateAcquired: this.props.asset.dateAcquired,
              files: _.map(this.props.asset.assetId.assetImages, file => { return { preview: file.signedUrl, name: this.props.asset.assetId.assetName } })
            })
          }}
        >Edit</Button>
        <Modal
          isOpen={this.state.showModal}
          onClose={() => {
            this.setState({
              showModal: false,
            })
          }}
          style={
            {
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
              },
              content: {
                margin: 'auto',
                maxWidth: '75%',
                height: 'fit-content',
              }
            }
          }
        >
          <VStack>
            <HStack>
              <Text fontSize={"2xl"} fontWeight={"bold"}>
                {_.isNil(this.state.asset) ? 'Add' : 'Edit'} an Asset
              </Text>
            </HStack>
            <HStack>
              <VStack flex={1} pr={3}>
                <FormControl mt="3" isInvalid={this.state.nameDirty && this.isNameInvalid()} isRequired={true}>
                  <FormControl.Label>Name</FormControl.Label>
                  <Input
                    onChangeText={(val) => {
                      this.setState({
                        nameDirty: true,
                        name: val,
                      })
                    }}
                    defaultValue={this.state.name}
                    isDisabled={true}
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid name.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl isInvalid={this.state.filesDirty && this.isFilesInvalid()} isRequired={true}>
                  <AddImage files={this.state.files} setFiles={this.setFiles} setFilesDirty={this.setFilesDirty} isDisabled={true} />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please upload an image for your Asset.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl mt="3" isInvalid={this.state.purchaseBasisDirty && this.isPurchaseBasisInvalid()} isRequired={true}>
                  <FormControl.Label>Purchase Price</FormControl.Label>
                  <InputGroup w={{
                    base: "70%",
                    md: "285"
                  }}>
                    <InputLeftAddon children={"$"} />
                    <NumberFormat
                      isDisabled={true}
                      thousandSeparator={true}
                      placeholder="123.45"
                      customInput={Input}
                      onValueChange={(val) => {
                        this.setState({
                          purchaseBasisDirty: true,
                          purchaseBasis: val,
                        });
                      }}
                      defaultValue={this.state.purchaseBasis}
                    />
                  </InputGroup>
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Purchase Price.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl mt="3" isInvalid={this.state.dateAcquiredDirty && this.isDateAcquiredInvalid()} isRequired={true}>
                  <FormControl.Label>Date Acquired</FormControl.Label>
                  <NumberFormat
                    isDisabled={true}
                    customInput={Input}
                    format="##/##/####"
                    placeholder="MM/DD/YYYY"
                    mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                    onValueChange={(val) => {
                      this.setState({
                        dateAcquiredDirty: true,
                        dateAcquired: val,
                      });
                    }}
                    defaultValue={!_.isNil(this.state.dateAcquired) ? spacetime(this.state.dateAcquired).format('numeric-us') : null}
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid date.
                  </FormControl.ErrorMessage>
                </FormControl>
                <FormControl mt="3" isInvalid={this.state.creatorNameDirty && this.isCreatorNameInvalid()} isRequired={true}>
                  <FormControl.Label>Creator Name</FormControl.Label>
                  <Input
                    isDisabled={true}
                    onChangeText={(val) => {
                      this.setState({
                        creatorNameDirty: true,
                        creatorName: val,
                      });
                    }}
                    defaultValue={this.state.creatorName}
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Creator Name.
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
              <VStack flex={1} pl={3} height={"100%"} minH={"100%"}>
                <FormControl mt="3" flex={1} h={"100%"} isInvalid={this.state.descriptionDirty && this.isDescriptionInvalid()} isRequired={true}>
                  <FormControl.Label>Description</FormControl.Label>
                  <Text>
                    {ReactHtmlParser(this.state.origDescription)}
                  </Text>
                  <FormControl.Label>Additional Description</FormControl.Label>
                  <JoditEditor
                    ref={this.editor}
                    value={this.state.description}
                    config={{
                      maxHeight: "100%",
                    }}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => {
                      this.setState({
                        descriptionDirty: true,
                        description: newContent,
                      });
                    }} // preferred to use only this option to update the content for performance reasons
                  />
                  <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                    Please enter a valid Description.
                  </FormControl.ErrorMessage>
                </FormControl>
              </VStack>
            </HStack>
            <HStack mt={5} space={"md"} justifyContent={"flex-end"}>
              <Button variant={"outline"} onPress={() => {
                this.reset();
                this.setState({
                  showModal: false,
                });
              }}>Cancel</Button>
              <Button
                isLoading={this.state.processing}
                isLoadingText={"Save"}
                isDisabled={this.state.processing || !this.isDirty() || (this.isDirty() && this.isInvalid())}
                onPress={async () => {
                  this.setState({
                    processing: true,
                  });

                  try {
                    const claims = await this.props.auth0.getIdTokenClaims();
                    const _asset = {};
                    if (this.state.nameDirty) {
                      _asset.assetName = this.state.name;
                    }
                    if (this.state.filesDirty) {
                      const assetImage = await addAssetImage(this.state.asset.assetId, this.state.files[0], claims.__raw);
                      _asset.assetImages = [assetImage];
                    }
                    if (this.state.purchaseBasisDirty) {
                      _asset.purchaseBasis = {
                        currency: 'USD',
                        value: this.state.purchaseBasis.floatValue,
                      };
                    }
                    if (this.state.dateAcquiredDirty) {
                      _asset.dateAcquired = spacetime(this.state.dateAcquired.formattedValue).format('iso-short');
                    }
                    if (this.state.creatorNameDirty) {
                      _asset.creatorName = this.state.creatorName;
                    }
                    if (this.state.descriptionDirty) {
                      _asset.assetDescription = `${this.state.origDescription}${this.state.description}`;
                    }
                    await this.props.updateAsset(
                      this.state.asset.id,
                      _asset,
                      claims.__raw
                    );
                    this.reset();
                    this.setState({
                      showModal:false
                    });
                    this.props.onSuccess();
                  } catch (_error) {
                    console.error(_error);
                    toast.error("There was an error processing your request.");
                  } finally {
                    this.setState({
                      processing: false,
                    });
                  }
                }}
              >
                Save
              </Button>
            </HStack>
          </VStack>
          <ToastContainer
              position={"top-center"}
              theme={"colored"}
              pauseOnFocusLoss={false}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client
  };
};

export default connect(
    mapStateToProps,
    {updateAsset}
)(withAuth0(withRouter(EditAsset)));