import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";

import './index.css';
import {App} from './App';
import {Auth0ProviderWithConfig} from './Auth0ProviderWithConfig';
import reportWebVitals from './reportWebVitals';
import ConfigureStore from "./redux/store.js";

const { persistor, store } = ConfigureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log(`Loading Application ${process.env.REACT_APP_VERSION}`);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <Auth0ProviderWithConfig>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Auth0ProviderWithConfig>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
