import React, {Component} from 'react';
import {
  Box,
  Button,
  Center, Heading,
  HStack, Icon, Spinner, Text,
  VStack,
} from 'native-base';
import {withAuth0} from '@auth0/auth0-react';
import _ from 'lodash';
import {toast, ToastContainer} from 'react-toastify';
import {connect} from 'react-redux';

import AppBar from './AppBar';
import {getAssets} from '../data/assets/actions';
import GridView from './GridView';
import AddAsset from './AddAsset';
import AssetCard from './AssetCard';
import Profile from './Profile';
import {getClient} from '../data/client/actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRotateRight} from '@fortawesome/free-solid-svg-icons';
import {withRouter} from '../util/router';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'ALL',
      showProfileModal: false,
      assetCards: [],
    }
  }

  componentDidMount = async () => {
    if (this.props.auth0.isAuthenticated) {
      try {
        const claims = await this.props.auth0.getIdTokenClaims();
        await this.props.getClient(claims.__raw);
        if (!_.has(this.props.client, 'accountOpened') || !_.has(this.props.client, 'termsAgreed') || !_.has(this.props.client, 'firstName') || !_.has(this.props.client, 'lastName') || !_.has(this.props.client, 'phone')) {
          this.setState({
            showProfileModal: true,
          });
        }
        await this.loadAssets();
      } catch (error) {
        console.error('Error loading user', error);
        if (error.response.status === 404) {
          this.setState({
            showProfileModal: true,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    }
    if (_.has(this.props.router.location, 'state') && _.has(this.props.router.location.state, 'hasError') && this.props.router.location.state.hasError) {
      toast.error(this.props.errorMessage || 'There was an error processing your request. Please try again later.');
    }
  }

  loadAssets = async () => {
    const claims = await this.props.auth0.getIdTokenClaims();
    await this.props.getAssets(claims.__raw);
    this.setState({
      assetCards: _.map(this.props.assets, asset => <AssetCard key={asset.assetId.assetId} asset={asset} />),
    });
  }

  onProfileModalClose = async () => {
    const claims = await this.props.auth0.getIdTokenClaims();
    const assets = await getAssets(claims.__raw);
    this.setState({
      assetCards: _.map(assets, asset => <AssetCard key={asset.assetId} assetId={asset.assetId} initial={false} isLoaded={false} />),
      assets,
    });
  }

  showProfileModal = (show) => {
    this.setState({
      showProfileModal: show,
    });
  }

  renderGrid = (assetCards) => {
    if (this.props.assetLoading) {
      return (
        <Center height={'400'} maxHeight={'400'} width={'100%'}>
          <VStack height={'400'} maxHeight={'400'} width={'100%'} flex={1}>
            <HStack flex={1} justifyContent={'center'}>
              <HStack space={2} justifyContent="center" alignItems={'center'}>
                <Spinner accessibilityLabel="Loading" color={"primary.600"} />
                <Heading color="primary.600" fontSize="md">
                  Loading
                </Heading>
              </HStack>
            </HStack>
          </VStack>
        </Center>
      );
    } else if (this.props.assetsLoaded && this.state.assetCards.length === 0) {
      return (
        <>
          <Center height={'400'} maxHeight={'400'} width={'100%'}>
            <VStack height={'400'} maxHeight={'400'} width={'100%'} flex={1}>
              <HStack flex={1} justifyContent={'center'}>
                <VStack flex={1} justifyContent={'center'} alignItems={'center'} space={6}>
                  <Text center textAlign={'center'} fontSize={'4xl'}>Welcome to your Portfolio Manager</Text>
                  <Text textAlign={'center'} fontSize={'2xl'} lineHeight={'43px'}>You can add your first asset by clicking the
                    <HStack fontSize={'md'} height={'43px'} px={2} flex={1}>
                      <AddAsset
                        onSuccess={() => {
                          this.setState({selectedTab: 'ALL'});
                          toast.success("Your asset has been added.");
                        }}
                      />
                    </HStack>
                    button above.</Text>
                </VStack>
              </HStack>
            </VStack>
          </Center>
        </>
      );
    } else if (assetCards.length === 0) {
      return (
        <Box>
          <Center height={'400'} maxHeight={'400'} width={'100%'}>
            <VStack height={'400'} maxHeight={'400'} width={'100%'} flex={1}>
              <HStack flex={1} justifyContent={'center'}>
                <VStack flex={1} justifyContent={'center'} alignItems={'center'} space={6}>
                  <Text textAlign={'center'} fontSize={'2xl'}>There are no assets to view.</Text>
                </VStack>
              </HStack>
            </VStack>
          </Center>
        </Box>
      );
    } else {
      return <GridView records={assetCards} pt={"3px"} width={"100%"} maxWidth={"1370px"} />;
    }
  }

  render() {
    let assetCards = _.times(8, (n) => <AssetCard key={n} isLoaded={false} />);

    if (this.props.assetsLoaded) {
      switch (this.state.selectedTab) {
        case 'PENDING': {
          assetCards = _.map(_.filter(this.props.assets, (a) => { return _.includes(['PENDING_TRANSFER', 'PENDING_PAYMENT'], a.ownershipStatus)}), asset => <AssetCard key={asset.assetId.assetId} asset={asset} />)
          break;
        }
        case 'OWN': {
          assetCards = _.map(_.filter(this.props.assets, (a) => { return _.includes(['OWN'], a.ownershipStatus)}), asset => <AssetCard key={asset.assetId.assetId} asset={asset} />)
          break;
        }
        case 'SOLD': {
          assetCards = _.map(_.filter(this.props.assets, (a) => { return _.includes(['SOLD'], a.ownershipStatus)}), asset => <AssetCard key={asset.assetId.assetId} asset={asset} />)
          break;
        }
        default: {
          assetCards = _.map(this.props.assets, asset => <AssetCard key={asset.assetId.assetId} asset={asset} />);
        }
      }
    }

    return (
      <>
        <AppBar />
        <VStack pt={"18px"} pb={"32px"} px={"32px"} w="100%">
          <Center>
            <VStack w={"100%"} maxWidth={"1370px"}>
              <HStack space={3}  flx={1}>
                <HStack flex={1}>
                  <HStack borderBottomWidth={1} borderColor={"primary.600"}>
                    <Button onPress={() => this.setState({selectedTab: 'ALL'})} variant={"ghost"} colorScheme={this.state.selectedTab === "ALL" ? "primary" : "secondary" }>ALL</Button>
                    <Button onPress={() => this.setState({selectedTab: 'OWN'})} variant={"ghost"} colorScheme={this.state.selectedTab === "OWN" ? "primary" : "secondary" }>OWN</Button>
                    <Button onPress={() => this.setState({selectedTab: 'SOLD'})} variant={"ghost"} colorScheme={this.state.selectedTab === "SOLD" ? "primary" : "secondary" }>SOLD</Button>
                    <Button onPress={() => this.setState({selectedTab: 'PENDING'})} variant={"ghost"} colorScheme={this.state.selectedTab === "PENDING" ? "primary" : "secondary" }>PENDING</Button>
                  </HStack>
                </HStack>
                <HStack space={3}>
                  <Button variant={"outline"} isLoading={this.props.assetLoading} onPress={() => this.loadAssets()}><Icon as={FontAwesomeIcon} icon={faRotateRight} color={"primary.600"} /></Button>
                  <AddAsset
                    onSuccess={() => {
                      this.setState({selectedTab: 'ALL'});
                      toast.success("Your asset has been added.");
                    }}
                  />
                </HStack>
              </HStack>
              {this.renderGrid(assetCards)}
            </VStack>
          </Center>
        </VStack>
        <Profile showModal={this.state.showProfileModal} setShowModal={this.showProfileModal} onClose={this.onProfileModalClose} />
        <ToastContainer
          position={"top-center"}
          theme={"colored"}
          pauseOnFocusLoss={false}
        />
      </>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    client: data.client,
    assets: data.assets.assets,
    assetsLoaded: data.assets.assetsLoaded,
    assetLoading: data.assets.loading,
    errorMessage: data.assets.errorMessage,
  };
};

export default connect(
  mapStateToProps,
  {
    getClient,
    getAssets
  }
)(withAuth0(withRouter(Home)));
