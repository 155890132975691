export const ASSET_ADD = 'assets/add';
export const ASSET_ADD_SUCCESS = 'asset/add/success';
export const ASSET_ADD_FAIL = 'asset/add/fail';
export const ASSET_DELETE = 'assets/delete';
export const ASSET_DELETE_SUCCESS = 'asset/delete/success';
export const ASSET_DELETE_FAIL = 'asset/delete/fail';
export const ASSET_PAYMENT = "assets/payment";
export const ASSET_PAYMENT_SUCCESS = "assets/payment/success";
export const ASSET_PAYMENT_FAIL = "assets/payment/fail";
export const ASSET_TRANSFER = "assets/transfer";
export const ASSET_TRANSFER_SUCCESS = "assets/transfer/success";
export const ASSET_TRANSFER_FAIL = "assets/transfer/fail";
export const ASSET_UPDATE = 'assets/update';
export const ASSET_UPDATE_SUCCESS = 'asset/update/success';
export const ASSET_UPDATE_FAIL = 'asset/update/fail';
export const ASSETS_LOADING = "assets/loading";
export const ASSETS_LOADING_SUCCESS = "assets/loading/success";
export const ASSETS_LOADING_FAIL = "assets/loading/fail";
