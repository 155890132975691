import {
  ASSET_ADD,
  ASSET_ADD_SUCCESS,
  ASSET_ADD_FAIL,
  ASSET_DELETE,
  ASSET_DELETE_SUCCESS,
  ASSET_DELETE_FAIL,
  ASSET_PAYMENT,
  ASSET_PAYMENT_SUCCESS,
  ASSET_PAYMENT_FAIL,
  ASSET_TRANSFER,
  ASSET_TRANSFER_SUCCESS,
  ASSET_TRANSFER_FAIL,
  ASSET_UPDATE,
  ASSET_UPDATE_SUCCESS,
  ASSET_UPDATE_FAIL,
  ASSETS_LOADING,
  ASSETS_LOADING_SUCCESS,
  ASSETS_LOADING_FAIL,
} from "./types";
import * as api from '../../util/api';
import {CLIENT_SET} from '../client/types';
import _ from 'lodash';

export const addAsset = (_asset, token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSET_ADD,
      });
      const asset = (await api.addAsset(_asset, token)).data;
      dispatch({
        type: ASSET_ADD_SUCCESS,
        payload: {
          asset,
        }
      });
    } catch (error) {
      console.error('Error loading assets', error);
      dispatch({
        type: ASSET_ADD_FAIL,
        payload: {
          errorMessage: 'There was an error processing your request. Please try again later.',
        }
      });
      throw error;
    }
  }
}

export const deleteAsset = (id, token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSET_DELETE,
      });
      await api.deleteAsset(id, token);
      dispatch({
        type: ASSET_DELETE_SUCCESS,
        payload: {
          assetId: id,
        }
      });
    } catch (error) {
      console.error('Error updating asset', error);
      dispatch({
        type: ASSET_DELETE_FAIL,
        payload: {
          errorMessage: 'There was an error processing your request. Please try again later.',
        }
      });
      throw error;
    }
  }
}

export const getAssets = (token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSETS_LOADING,
      });
      const assets = (await api.getAssets(token)).data;
      dispatch({
        type: ASSETS_LOADING_SUCCESS,
        payload: {
          assets,
        }
      });
    } catch (error) {
      console.error('Error loading assets', error);
      dispatch({
        type: ASSETS_LOADING_FAIL,
        payload: {
          errorMessage: 'There was an error processing your request. Please try again later.',
        }
      });
      throw error;
    }
  }
}

export const updateAsset = (assetId, asset, token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSET_UPDATE,
      });
      const _asset = (await api.updateAsset(assetId, asset, token)).data;
      dispatch({
        type: ASSET_UPDATE_SUCCESS,
        payload: {
          asset: _asset,
        }
      });
    } catch (error) {
      console.error('Error updating asset', error);
      const _payload = {
        errorMessage: 'There was an error processing your request. Please try again later.',
      }
      if (_.has(error, 'response') && _.has(error.response, 'data') && error.response.data.code === 'TRANSFER_CANCELLED') {
        _payload.errorMessage = 'The transfer has already been cancelled.';
      }
      dispatch({
        type: ASSET_UPDATE_FAIL,
        payload: _payload,
      });
      throw error;
    }
  }
}
export const cancelTransfer = (transferId, side, token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSET_UPDATE,
      });
      const _asset = (await api.cancelTransfer(transferId, token)).data;
      dispatch({
        type: ASSET_UPDATE_SUCCESS,
        payload: {
          asset: side === 'seller' ? _asset.seller : _asset.buyer,
        }
      });
    } catch (error) {
      console.error('Error updating asset', error);
      const _payload = {
        errorMessage: 'There was an error processing your request. Please try again later.',
      }
      if (_.has(error, 'response') && _.has(error.response, 'data') && error.response.data.code === 'TRANSFER_CANCELLED') {
        _payload.errorMessage = "Transfer has already been cancelled.";
        _payload.asset = side === 'seller' ? error.response.data.seller : error.response.data.buyer;
        _payload.side = side;
      }
      dispatch({
        type: ASSET_UPDATE_FAIL,
        payload: _payload,
      });
      throw error;
    }
  }
}


export const createTransfer = (assetId, data, token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSET_TRANSFER,
      });
      const _asset = (await api.createTransfer(assetId, data, token)).data;
      dispatch({
        type: ASSET_TRANSFER_SUCCESS,
        payload: {
          asset: _asset.assetOwner,
        }
      });
      dispatch({
        type: CLIENT_SET,
        payload: {
          asset: _asset.client,
        }
      });
    } catch (error) {
      console.error('Error updating asset', error);
      dispatch({
        type: ASSET_TRANSFER_FAIL,
        payload: {
          errorMessage: 'There was an error processing your request. Please try again later.',
        }
      });
      throw error;
    }
  }
}

export const completePayment = (transferId, data, token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSET_PAYMENT,
      });
      const response = (await api.completePayment(transferId, data, token)).data;
      dispatch({
        type: ASSET_PAYMENT_SUCCESS,
        payload: {
          asset: response.assetOwner,
        }
      });
      dispatch({
        type: CLIENT_SET,
        payload: {
          asset: response.client,
        }
      });
      return response;
    } catch (error) {
      console.error('Error updating asset', error);
      const _payload = {
        errorMessage: 'There was an error processing your request. Please try again later.',
      }
      if (_.has(error, 'response') && _.has(error.response, 'data') && error.response.data.code === 'TRANSFER_CANCELLED') {
        _payload.errorMessage = 'The transfer has been cancelled.';
      }
      dispatch({
        type: ASSET_PAYMENT_FAIL,
        payload: _payload,
      });
      throw error;
    }
  }
}

export const completeTransfer = (transferId, data, token) => {
  return async dispatch => {
    try {
      dispatch({
        type: ASSET_PAYMENT,
      });
      const response = (await api.completeTransfer(transferId, data, token)).data;
      dispatch({
        type: ASSET_PAYMENT_SUCCESS,
        payload: {
          asset: response,
        }
      });
      return response;
    } catch (error) {
      console.error('Error updating asset', error);
      const _payload = {
        errorMessage: 'There was an error processing your request. Please try again later.',
      }
      if (_.has(error, 'response') && _.has(error.response, 'data') && error.response.data.code === 'TRANSFER_CANCELLED') {
        _payload.errorMessage = 'The transfer has been cancelled.';
      }
      dispatch({
        type: ASSET_PAYMENT_FAIL,
        payload: _payload,
      });
      throw error;
    }
  }
}
