import React from "react";
import {Box, Divider, HStack, Text, VStack} from 'native-base';
import {parsePhoneNumber} from 'awesome-phonenumber';
import numeral from 'numeral';
import _ from 'lodash';
import { stripHtml } from 'string-strip-html';

export const ExhibitA = ({asset, seller, buyer, purchasePrice, serviceFee, royalties, amount}) => {
  return (
    <VStack space={1} mt={3} flex={1}>
      <Text textAlign={"center"} fontWeight={"bold"}>Exhibit A</Text>
      <Text textAlign={"center"} fontWeight={"bold"}>Summary of Terms and Conditions</Text>
      {_.has(seller, 'owner') && seller.owner === 'Private' && (
        <Text textAlign={"center"}><Text bold>Seller:</Text>: {seller.owner}</Text>
      )}
        {_.has(seller, 'owner') && seller.owner !== 'Private' && (
          <>
            <Text textAlign={"center"}><Text bold>Seller:</Text>: {seller.owner}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Phone:</Text> {new parsePhoneNumber(seller.phone, 'US').getNumber('national')}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Email:</Text> {seller.email}</Text>
          </>
        )}
        {!_.has(seller, 'owner') && (
          <>
            <Text textAlign={"center"}><Text bold>Seller:</Text>: {seller.firstName} {seller.lastName}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Phone:</Text> {new parsePhoneNumber(seller.phone, 'US').getNumber('national')}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Email:</Text> {seller.email}</Text>
          </>
        )}
      <Text textAlign={"center"} fontWeight={"bold"}>hereby sells</Text>
      <Text textAlign={"center"}><Text bold>Asset:</Text> {asset.assetName}</Text>
      <Text textAlign={"center"} fontWeight={"bold"}>to</Text>
        {_.has(buyer, 'owner') && buyer.owner === 'Private' && (
            <Text textAlign={"center"}><Text bold>Buyer:</Text>: {buyer.owner}</Text>
        )}
        {_.has(buyer, 'owner') && buyer.owner !== 'Private' && (
          <>
            <Text textAlign={"center"}><Text bold>Buyer:</Text>: {buyer.owner}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Phone:</Text> {new parsePhoneNumber(buyer.phone, 'US').getNumber('national')}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Email:</Text> {buyer.email}</Text>
          </>
        )}
        {!_.has(buyer, 'owner') && (
          <>
            <Text textAlign={"center"}><Text bold>buyer:</Text>: {buyer.firstName} {buyer.lastName}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Phone:</Text> {new parsePhoneNumber(buyer.phone, 'US').getNumber('national')}</Text>
            <Text textAlign={"center"}><Text fontWeight={"bold"}>Email:</Text> {buyer.email}</Text>
          </>
        )}
      <Text textAlign={"center"}>with {numeral(royalties.firstSeller.percent).format('0.0%')} to First Owner and</Text>
      <Text textAlign={"center"}>{numeral(royalties.laterOwners.percent).format('0.0%')} to Subsequent Owners.</Text>
      <Divider width={'75%'} alignSelf={'center'} mt={3} />
      <HStack width={'75%'} mt={3} space={3} alignSelf={'center'}>
        <Box flex={1} px={4}>
          <VStack space={1} flex={1}>
            <Text textAlign={"center"} fontWeight={"bold"}>Service Payments</Text>
            <HStack justifyContent={'space-between'}>
              <Text>Purchase Price</Text>
              <Text>{numeral(purchasePrice).format('$0,0.00')}</Text>
            </HStack>
            <HStack justifyContent={'space-between'}>
              <Text>Service Fee ({numeral(serviceFee.percent).format('0.0%')})</Text>
              <Text>{numeral(serviceFee.amount).format('$0,0.00')}</Text>
            </HStack>
            <HStack justifyContent={'space-between'}>
              <Text>Royalty to First Seller ({numeral(royalties.firstSeller.percent).format('0.0%')})</Text>
              <Text>{numeral(royalties.firstSeller.amount).format('$0,0.00')}</Text>
            </HStack>
            <HStack justifyContent={'space-between'}>
              <Text>Royalty to Later Owners ({numeral(royalties.laterOwners.percent).format('0.0%')})</Text>
              <Text>{numeral(royalties.laterOwners.amount).format('$0,0.00')}</Text>
            </HStack>
            {_.has(royalties, 'thirdParties') && !_.isEmpty(royalties.thirdParties) && royalties.thirdParties.amount > 0 && (
                <HStack justifyContent={'space-between'}>
                  <Text>Royalty to Third Parties ({numeral(royalties.thirdParties.percent).format('0.0%')})</Text>
                  <Text>{numeral(royalties.thirdParties.amount).format('$0,0.00')}</Text>
                </HStack>
            )}
            <HStack justifyContent={'space-between'}>
              <Text bold>Total</Text>
              <Text bold>{numeral(amount).format('$0,0.00')}</Text>
            </HStack>
          </VStack>
        </Box>
      </HStack>
      <Divider width={'75%'} alignSelf={'center'} mt={3} />
      <HStack width={'75%'} mt={3} space={3} alignSelf={'center'}>
        <Box flex={1} px={4}>
          <VStack space={1} flex={1}>
            <Text textAlign={"center"} fontWeight={"bold"}>Additional Information</Text>
            <Text textAlign={"center"}><Text bold>Creator:</Text> {asset.creatorName}</Text>
            <Text textAlign={"center"} bold>Description:</Text>
            <Text textAlign={"center"}>{_.truncate(stripHtml(asset.assetDescription).result, {length: 100})}</Text>
          </VStack>
        </Box>
      </HStack>
    </VStack>
  );
}
