import React from "react";
import {Box, Center, Heading, HStack, Spinner} from 'native-base';

export const Loading = () => {
  return (
      <Center height={"100vh"} bg={"coolGray.300"}>
        <Box
            borderRadius={10}
            pt={5}
            pb={6}
            bg="white"
            width={558}
            height={545}
            shadow={"card"}
            alignItems={"center"}
            justifyContent={"center"}
        >
          <HStack space={2} justifyContent="center">
            <Spinner accessibilityLabel="Loading" color={"primary.600"} />
            <Heading color="primary.600" fontSize="md">
              Loading
            </Heading>
          </HStack>
        </Box>
      </Center>
  );
}
