import React, {Component} from 'react';
import {
  VStack,
  Box,
  Center,
} from 'native-base';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay';

import './TransferAsset.css';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

class PayPalButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
    }
  }

  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: this.props.asset.transferredFromId.amount,
            currency_code: 'USD'
          },
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      }
    });
  }

  onApprove = (data) => {
    this.setState({ processing: true });
    this.props.onSuccess(data);
  }

  render() {
    return (
      <Box flex={1}>
        <LoadingOverlay
            active={this.state.processing}
            spinner
        >
          <VStack>
            <Center>
              <PayPalScriptProvider options={{'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID, }}>
                <PayPalButtons
                  createOrder={(data, actions) => this.createOrder(data, actions)}
                  onApprove={(data, actions) => this.onApprove(data, actions)}
                />
              </PayPalScriptProvider>
            </Center>
          </VStack>
        </LoadingOverlay>
      </Box>
    );
  }
}

export default PayPalButton;
