import React from 'react';
import {Box, VStack, Text, Pressable, Image, HStack} from 'native-base';
import _ from 'lodash';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import numeral from 'numeral';
import { useNavigate } from "react-router-dom";

import AssetImage from './AssetImage';
import {getDate, getOwnershipStatus} from '../util';

export default function AssetCard({asset = null, key = ''}) {
  const navigate = useNavigate();

  const isLoading = _.isNil(asset);

  if (isLoading) {
    return (
      <VStack key={key} justifyContent={"space-between"} flex={1} borderWidth={1} borderColor={"muted.200"} shadow={3} my={4} py={2} rounded={"md"} w={"100%"} h={403}>
        <HStack px={4} py={1} alignItems={'center'}>
          <ReactPlaceholder type='text' rows={1} ready={!isLoading} showLoadingAnimation={isLoading}>
            <Text fontSize={'lg'} fontWeight={'400'}></Text>
          </ReactPlaceholder>
        </HStack>
        <Box px={4} py={1}>
          <ReactPlaceholder rows={1} ready={!isLoading} type={"text"} showLoadingAnimation={isLoading}>
            <Text></Text>
          </ReactPlaceholder>
        </Box>
        <Box py={1}>
          <AssetImage ready={!isLoading} />
        </Box>
        <Box px={4} py={1}>
          <ReactPlaceholder rows={1} ready={!isLoading} type={"text"} showLoadingAnimation={isLoading}>
            <Text></Text>
          </ReactPlaceholder>
        </Box>
        <Box px={4} py={1}>
          <ReactPlaceholder rows={1} ready={!isLoading} type={"text"} showLoadingAnimation={isLoading}>
            <Text></Text>
          </ReactPlaceholder>
        </Box>
        <Box px={4} py={1}>
          <ReactPlaceholder rows={1} ready={!isLoading} type={"text"} showLoadingAnimation={isLoading}>
            <Text></Text>
          </ReactPlaceholder>
        </Box>
      </VStack>
    )
  }
  return (
    <Pressable key={key} onPress={() => navigate(`/assets/${asset.id}`, {replace: true, state: {asset}})}>
      <VStack justifyContent={"space-between"} flex={1} borderWidth={1} borderColor={"muted.200"} shadow={3} my={4} py={2} rounded={"md"} w={"100%"} h={403}>
        <HStack px={4} py={1} alignItems={'center'}>
          <Text fontSize={'lg'} fontWeight={'400'}>{getOwnershipStatus(asset.ownershipStatus)}</Text>
          {_.has(asset.assetId, 'previousOwners') && asset.assetId.previousOwners.length > 0 && <Image size={'1.5em'} ml={1} source={{uri: 'https://cdn.copyforward.com/icon.png'}} />}
        </HStack>
        <Box px={4} py={1}>
          <Text>Name: {asset.assetId.assetName}</Text>
        </Box>
        <Box py={1}>
          <AssetImage image={!_.isNil(asset.assetId.assetImages) && _.isArray(asset.assetId.assetImages) ? asset.assetId.assetImages[0].signedUrl : ''} alt={asset.assetId.assetName} ready={true} />
        </Box>
        <Box px={4} py={1}>
          <Text>Purchase Price: {numeral(asset.purchaseBasis.value).format('$0,0.00')}</Text>
        </Box>
        <Box px={4} py={1}>
          <Text>Date Acquired: {getDate(asset.dateAcquired)}</Text>
        </Box>
        <Box px={4} py={1}>
          <Text>Creator Name: {asset.assetId.creatorName}</Text>
        </Box>
      </VStack>
    </Pressable>
  )
}