import { CLIENT_SET } from "./types";
import {createUser, getUser, updateUser} from '../../util/api';
import _ from 'lodash';

export const getClient = (token) => {
  return async dispatch => {
    const client = await getUser(token);
    dispatch({
      type: CLIENT_SET,
      payload: {
        client: client.data,
      }
    });
  }
}

export const saveClient = (data, token) => {
  return async dispatch => {
    const client = await createUser(
      {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        accountOpened: data.accountOpened,
        termsAgreed: data.termsAgreed,
      },
        token,
    );
    dispatch({
      type: CLIENT_SET,
      payload: {
        client: client.data,
      }
    });
  }
}

export const updateClient = (data, token) => {
  return async dispatch => {
    const _data = {};
    if (_.has(data, 'firstName')) {
      _data.firstName = data.firstName;
    }
    if (_.has(data, 'lastName')) {
      _data.lastName = data.lastName;
    }
    if (_.has(data, 'phone')) {
      _data.phone = data.phone;
    }
    if (_.has(data, 'accountOpened')) {
      _data.accountOpened = data.accountOpened;
    }
    if (_.has(data, 'termsAgreed')) {
      _data.termsAgreed = data.termsAgreed;
    }
    const client = await updateUser(
      _data,
      token,
    );
    dispatch({
      type: CLIENT_SET,
      payload: {
        client: client.data,
      }
    });
  }
}

export const setClient = client => {
  return async dispatch => {
    dispatch({
      type: CLIENT_SET,
      payload: {
        client
      }
    });
  };
};
