import React from "react";
import {Box} from 'native-base';
import {Col, Container, Row} from 'react-grid-system';
import _ from 'lodash';

export default function GridView({records, ...rest}) {
  return (
      <Box {...rest}>
        <Container>
          <Row>
            {
              _.map(records, record => {
                return (
                  <Col lg={3} md={4} sm={6} xs={12} key={record.assetId} style={{minWidth: 312.5}}>
                    {record}
                  </Col>
                )
              })
            }
          </Row>
        </Container>
      </Box>
  );
}